const initialState = {
    orgProfile: {
    },
    ActiveClassProfilePage: 'orgOverViewActive',
    activePage:{pageTitle:'',pageType:''}
}

const OrganisationReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'set_org_profile_data':
            return { ...state, orgProfile: action.profileData };

        case 'set_active_class_org':
            return { ...state, ActiveClassProfilePage: action.value };

        case 'org_update_booking_record':
            var obj1  = state.orgProfile;
            var obj2 = action.profileData;
            var clonedObj = { ...obj1 };
            var mergedObj = { ...obj1, ...obj2 };
            return { ...state, orgProfile: mergedObj };
            
        case 'set_active_page_org':
        return {...state, activePage: action.value};
    
        default:
            return state
    }
}

export default OrganisationReducer
    