import {memberActiveTitle} from 'menujson/member_menu_json';
import { postData } from 'service/common.js';

export const setMemberProfileData = (profileData) => ({
        type: 'set_member_profile_data',
        profileData
    })

export const updateMemberProfileData = (profileData) => ({
        type: 'member_update_profile_record',
        profileData
    })

export const setMemberActiveClass = (value) => ({
        type: 'set_active_class_member',
        value
    })

export const setActiveSelectPageData= (value) => {
    return {
        type: 'set_active_page_member',
        value
}}


export function setActiveSelectPage(request) {
    return (dispatch, getState) => {
        let pageData =memberActiveTitle;
        let pageType = pageData.hasOwnProperty(request) ? request: 'details';
        let pageTypeTitle = pageData[pageType];
        return dispatch(setActiveSelectPageData({pageType:pageType,pageTitle:pageTypeTitle}))
    }
};

 /**
 * RequestData get the list of document
 * @param {int} pageSize 
 * @param {int} page 
 * @param {int} sorted 
 * @param {int} filtered 
 */
export const getDocumentListByPage = (entity_id, url, user_page, pageSize, page, sorted, filtered) => {

    return new Promise((resolve, reject) => {
        let Request = {}
        // request json
        if(user_page == 'member'){
             Request = { member_id: entity_id, pageSize: pageSize, page: page, sorted: sorted, filtered: filtered };
        }else{
             Request = { participant_id: entity_id, pageSize: pageSize, page: page, sorted: sorted, filtered: filtered };

        }
        postData(url, Request).then((result) => {
            if (result.status) {
                let filteredData = result.data;
                const res = {
                    rows: filteredData,
                    pages: (result.count),
                    count: result.document_count,
                    total_count: result.total_item
                };
                resolve(res);
            } else {
                const res = {
                    rows: [],
                    pages: 0,
                    document_count: 0
                };
                resolve(res);
            }
           
        });

    });
}
