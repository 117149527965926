import { postData} from 'service/common.js';
import {clearImailNotification} from './../../notification/actions/NotificationAction';

// action for set internal mail data listing
export function setMessageListing(mail_data) {
    return {
        type: 'set_internal_imail_message_listing',
        mail_data
    }
}

// action for remove mail from listing
export const setMessageAllContent = (mail_data) => ({
        type: 'set_internal_imail_all_content',
        mail_data
})

// action for remove mail from listing
export const removeMailFromListing = (messageId) => ({
        type: 'remove_internal_mail_from_listing',
        messageId
    })   

// action update mail content data value
export const updateMailContentValue = (update_data) => ({
        type: 'update_internal_mail_content_value',
        update_data
    })

// action update listing value
export const updateMailListingValue = (update_data, messageId) => ({
        type: 'update_internal_mail_listing_value',
        messageId: messageId,
        update_data
    })

// action for set reply mail
export const replyMail = (status, messageId) => ({
        type: 'reply_mail_of_internal_mail',
        status: status,
        messageId
})    

// action for set status create new mail
export const composeNewMailStatus = (status) => ({
        type: 'compose_new_internal_mail',
        status
})  
	
// action for set mail type
export const setInternalMailType = (mail_type) => ({
        type: 'set_internal_mail_type',
        mail_type
    })
    
// action for set fetching status true or false
export const setFetchingStatusRequest = (value) => ({
        type: 'set_internal_mail_fetching_status',
        value
})    

// action for set fetching status true or false
export const setFetingMailDetailsStatus = (value) => ({
        type: 'set_internal_mail_details_fetching_status',
        value
})  

// ascronus middleware for fetch data 
function fetchInternalMail(request) {
    return dispatch => {
        dispatch(setFetchingStatusRequest(true))
        return postData('imail/Internal_imail/get_internal_messages', request).then((result) => {
            if (result.status) {
                dispatch(setMessageListing(result.data))
            }
        });
    }
}

// middle ware for set data
export function getInternalMailListing(request) {
    return (dispatch, getState) => {
        return dispatch(fetchInternalMail(request))
    }
}


// ascronus middleware for fetch data of mail content
function fetchMailContent(request) {
    return dispatch => {
         dispatch(setFetingMailDetailsStatus(true))
        return postData('imail/Internal_imail/get_single_chat', request).then((result) => {
            if (result.status) {
                 dispatch(setMessageAllContent(result.data));

                 if(result.data.content.length > 0){
                    result.data.content.map((val, index) => {
                        if(val.is_notify == 0 && val.is_read == 0){
                            dispatch(clearImailNotification({contactId: val.id, type : 'internal'}))
                        }
                    })
                }
            }else{
               
            }
             dispatch(setFetingMailDetailsStatus(false))
        });
    }
}

// middle ware for set data of mail content
export function getInternalMailContent(request) {
    return (dispatch, getState) => {
        return dispatch(fetchMailContent(request))
    }
}

/*
 * action for set data group chat data
 */
export const setGroupChatData = (data) => ({
        type: 'set_group_chat_data',
        data
})

/*
 * action for set new message group chat data
 */
export const addNewGroupMessage = (message_data) => ({
        type: 'add_new_group_message',
        message_data
})

/*
 * action for set message as read
 */
export const setGroupMessageAsRead = (index) => ({
        type: 'set_group_message_as_read',
        index,
})

/*
 * action for set current active team id and current team type
 */
export const setCurrentTeamIdAndType = (teamId, team_type) => ({
        type: 'set_current_teamId_and_type',
        teamId,
        team_type,
})