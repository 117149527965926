import React, { Component } from 'react';
import { connect } from 'react-redux';
import PDFViewerMY from 'service/PDFViewerMY.js';
import { postData,toastMessageShow,archiveALL} from 'service/common.js';
import { Link,Redirect } from 'react-router-dom';
import {setHeaderFooterVisibility } from '../../../actions/PermissionAction.js';

class OrganisationViewInvoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeCol: '',
        }
    }

    componentDidMount() {
        this.props.setHeaderFooterVisibility(false);
        this.getInvoicePdf();
    }
   
    getInvoicePdf=()=>{
        this.setState({ loading: true });
            postData('finance/FinanceInvoice/get_invoice_pdf', this.props.match.params).then((result) => {           
            if (result.status) {
               this.setState(result.data);
            }else if(result.hasOwnProperty('error') || result.hasOwnProperty('msg')){
                let msg = result.hasOwnProperty('error') ? result.error:result.msg;
                toastMessageShow(msg,'e');
            }
            this.setState({ loading: false });
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row py-4">
                            <div className="col-lg-12">
                                {this.state.file_path!=undefined ? <PDFViewerMY loadData={{src:this.state.file_path||'',extraclassName:'disableToolbar'}} />:null}
                            </div>
                        </div>                      
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => ({
    showPageTitle: state.FinanceReducer.activePage.pageTitle,
    showTypePage: state.FinanceReducer.activePage.pageType
})
const mapDispatchtoProps = (dispach) => {
    return {
        setHeaderFooterVisibility: (status) => dispach(setHeaderFooterVisibility(status)),
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(OrganisationViewInvoice);





