import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_PATH } from '../../../config.js';
import Header from '../../admin/Header';
import Footer from '../../admin/Footer';
import MembersList from './MembersList';
import { checkItsNotLoggedIn, postData } from '../../../service/common.js';
import { dashboardFilterByType } from '../../../dropdown/memberdropdown.js';
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { setSubmenuShow } from 'components/admin/actions/SidebarAction';
import { CounterShowOnBox } from 'service/CounterShowOnBox.js';

class MemberDashboard extends React.Component {
    constructor(props) {
        super(props);
        checkItsNotLoggedIn(ROUTER_PATH);

        this.state = {
            srch_box: '',
            search_value: '',
            include_inactive: false,
            filtered: '',
            testimonial: []
        };
        this.onChange = this.onChange.bind(this);
        this.updateValue = this.updateValue.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    updateValue(val) {
        this.setState = { srch_box: val };
    }

    searchData(e) {
        e.preventDefault();
        var srch_ary = {
            'srch_box': this.state.search_value,
            'include_inactive': this.state.include_inactive,
            'search_by': this.state.filterByType,
        }
        this.setState({ filtered: srch_ary }, function () { });
    }

    onChange(e) {
        var state = {};
        state[e.target.name] = (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
        this.setState(state);
    }

    componentDidMount() {
        this.props.setSubmenuShow(0);
        this.getMemberTestimonial();
    }

    getMemberTestimonial = () => {
        postData('member/MemberAction/get_member_testimonial').then((result) => {
            if (result.status) {
                this.setState({ testimonial: result.data }, () => { });

            }
        });
    }

    render() {
        return (
            <div>
                {/* <script src={'assets/js/site_js/counter.js'} /> */}

                <div className="row  _Common_back_a">
                    <div className="col-lg-12 col-md-12">
                        <Link className="d-inline-flex" to={`${ROUTER_PATH}admin/dashboard`}><span className="icon icon-back-arrow back_arrow"></span></Link>
                    </div>
                </div>
                <div className="row"><div className="col-lg-12 col-md-12"><div className="bor_T"></div></div></div>

                <div className="row _Common_He_a">
                    <div className="col-lg-9  col-md-12">
                        <h1 className="color">Members</h1>
                    </div>
                    <div className="col-lg-3">
                        <Link className="C_NeW_BtN w-100" to={`${ROUTER_PATH}admin/support_worker/create_new_member`}><span>Create New Member</span><i className="icon icon icon-add-icons"></i></Link>
                    </div>
                </div>

                <div className="row"><div className="col-lg-12 col-md-12"><div className="bor_T"></div></div></div>

                <div className="row">
                    <div className="col-lg-12 col-md-12">

                        {this.state.testimonial.map((val, index) => (
                            <ul className="landing_graph mt-5">
                                <li className="radi_2 w-100 text-center">
                                    <h3 className="text-center  d-inline-block">{val.title}:</h3>
                                    <h3 className="d-inline-block color">"{val.testimonial}"</h3>
                                    <span className="name d-inline-block"><small>- {val.full_name}</small></span>
                                </li>
                            </ul>
                        ))}

                    </div>
                </div>

                <div className="row status_row-- pt-3 after_before_remove justify-content-center">
                    <MemberGraphCount />
                    <MemberCount />
                </div>

                {/* <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <ul className="landing_graph landing_graph_item_2 mt-5">
                            <MemberGraphCount />
                            <MemberCount />
                        </ul>
                    </div>
                </div> */}


                <div className="mt-0">
                    <div className="row"><div className="col-lg-12 col-md-12"><div className="bor_T"></div></div></div>
                    <form method='get' onSubmit={this.searchData.bind(this)} autoComplete="off">
                        <div className="row _Common_Search_a">
                            <div className="col-lg-7 col-xs-6">
                                <div className="search_bar ad_search_btn right srchInp_sm actionSrch_st">
                                    <input type="text" className="srch-inp" placeholder="Search" name='search_value' value={this.state.search_value} onChange={this.onChange} />
                                    <button type="submit"><i className="icon icon-search2-ie"></i></button>
                                </div>
                                {/* <div className="search_bar">
                                        <div className="input_search">
                                            <input type="text" className="form-control" placeholder="Search" name='search_value' value={this.state.search_value} onChange={this.onChange} />
                                            <button type="submit"><span className="icon icon-search"></span></button>
                                        </div>
                                    </div> */}
                            </div>

                            <div className="col-lg-2 col-xs-3">
                                <div className="row">
                                    <span className="col-md-12 include_box">
                                        <input type="checkbox" className="checkbox_big" id="Include" name="include_inactive" checked={this.state['include_inactive']} value={this.state.include_inactive || ''} 
                                            onChange={(e) => this.setState({ 
                                                include_inactive: e.target.checked, 
                                                filtered: { 
                                                    include_inactive: e.target.checked, 
                                                    filterByType: this.state.filtered.filterByType,
                                                    srch_box:this.state.search_value
                                                } })} 
                                        />
                                        <label htmlFor="Include"><span></span><small className="pl-1">Include Inactive</small></label>
                                    </span>
                                </div>
                            </div>

                            <div className="col-lg-3 col-xs-3">
                                <div className="box">
                                    <Select name="filterByType" required={true} simpleValue={true} searchable={false} clearable={false} value={this.state.filtered.filterByType || ''} 
                                        onChange={(e) => this.setState({ 
                                            filtered: { 
                                                filterByType: e, 
                                                include_inactive: this.state.include_inactive ,
                                                srch_box:this.state.search_value
                                            } })}
                                        options={dashboardFilterByType(0, 'clearable')} placeholder="Filter by type" />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="col-lg-12 col-md-12"><div className="bor_T"></div></div>
                </div>

                <div className="col-lg-12 col-md-12" ></div>

                <MembersList filtered={this.state.filtered} />


            </div>
        );
    }
}
const mapStateToProps = state => ({
})

const mapDispatchtoProps = (dispach) => {
    return {
        setSubmenuShow: (result) => dispach(setSubmenuShow(result))
    }
};
export default connect(mapStateToProps, mapDispatchtoProps)(MemberDashboard);

//
class MemberCount extends React.Component {
    constructor(props) {
        super(props);
        this.state = { MemberCount: 0, view_type: 'month', AllMemberCount: 0 };

    }

    componentDidMount() {
        this.memberAjax();
    }

    memberCountBy(type) {
        this.setState({ view_type: type }, function () {
            this.memberAjax();
        });
    }

    //
    memberAjax() {
        postData('member/MemberDashboard/member_count', this.state).then((result) => {
            if (result.status) {
                this.setState({ MemberCount: result.member_count, AllMemberCount: result.all_member_count });
            } else {
                this.setState({ error: result.error });
            }
        });
    }

    render() {
        return (


            <React.Fragment>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="status_box1">

                        <h4 className="hdng">New Member Counter:</h4>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 colJ-1">
                                <CounterShowOnBox counterTitle={this.state.MemberCount} classNameAdd="" mode="recruitment" />
                                <div className="duly_vw">
                                    <div className="viewBy_dc text-center">
                                        <h5>View By:</h5>
                                        <ul>
                                            <li onClick={() => this.memberCountBy('week')} className={(this.state.view_type == 'week') ? 'active' : ''}>Week</li>
                                            <li onClick={() => this.memberCountBy('month')} className={(this.state.view_type == 'month') ? 'active' : ''}>Month </li>
                                            <li onClick={() => this.memberCountBy('year')} className={(this.state.view_type == 'year') ? 'active' : ''}>Year </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <li className="radi_2">
                    <h2 className="text-center cl_black">New Member Counter:</h2>
                    <div className="row  pb-3 align-self-center w-100 mx-auto Graph_flex">
                        <div className="col-md-8 col-xs-12 d-inline-flex align-self-center justify-content-center mb-3">
                            <CounterShowOnBox counterTitle={this.state.MemberCount} classNameAdd="" />
                        </div>
                        <div className="W_M_Y_box  col-md-4 col-xs-12 d-inline-flex align-self-center">
                            <div className="vw_bx12 mx-auto">
                                <h5><b>View By</b></h5>
                                <span onClick={() => this.memberCountBy('week')} className={this.state.view_type == 'week' ? 'color' : ''}>Week</span><br />
                                <span onClick={() => this.memberCountBy('month')} className={this.state.view_type == 'month' ? 'color' : ''}>Month </span><br />
                                <span onClick={() => this.memberCountBy('year')} className={this.state.view_type == 'year' ? 'color' : ''}> Year</span>
                            </div>
                        </div>
                    </div>
                </li> */}

            </React.Fragment>
        );
    }
}

class MemberGraphCount extends React.Component {
    constructor(props) {
        super(props);
        this.state = { MemberCount: 0, view_type: 'month', AllMemberCount: 0 };

    }

    componentDidMount() {
        this.memberAjax();
    }

    memberCountBy(type) {
        this.setState({ view_type: type }, function () {
            this.memberAjax();
        });
    }

    //
    memberAjax() {
        postData('member/MemberDashboard/member_count', this.state).then((result) => {
            if (result.status) {
                this.setState({ MemberCount: result.member_count, AllMemberCount: result.all_member_count });
            } else {
                this.setState({ error: result.error });
            }
        });
    }

    render() {
        const data = {
            labels: ['Other Member', 'New'],
            datasets: [{
                data: [(this.state.AllMemberCount - this.state.MemberCount), this.state.MemberCount],
                backgroundColor: ['#fe7b31', '#ee5802'],
                hoverBackgroundColor: ['#fe7b31', '#ee5802'],
                borderWidth: 0,
            }],

        };

        return (
            <React.Fragment>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="status_box1">

                        <h4 className="hdng">Todays Total:</h4>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 colJ-1">
                                <div className='mb-3'>
                                    <Doughnut data={data} height={210} className="myDoughnut" legend={{ display: false }} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 colJ-1">
                                <ul className="status_det_list">
                                    <li className="drk-color1" >New = {this.state.MemberCount}</li>
                                    <li className="drk-color2">Total = {this.state.AllMemberCount}</li>
                                </ul>
                                <div className="viewBy_dc text-center">
                                    <h5>View By:</h5>
                                    <ul>
                                        <li onClick={() => this.memberCountBy('week')} className={(this.state.view_type == 'week') ? 'active' : ''}>Week</li>
                                        <li onClick={() => this.memberCountBy('month')} className={(this.state.view_type == 'month') ? 'active' : ''}>Month</li>
                                        <li onClick={() => this.memberCountBy('year')} className={(this.state.view_type == 'year') ? 'active' : ''}>Year</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <li className="radi_2">
                    <h2 className="text-center P_15_b cl_black">New Member Graph:</h2>
                    <div className="row  pb-3 align-self-center w-100 mx-auto Graph_flex">
                        <div className="text-center col-md-5 col-xs-12">
                            <div className="myChart12 mx-auto" >
                                <Doughnut height={210} data={data} legend={{ display: false }} />
                            </div>
                        </div>
                        <div className="col-md-4 col-xs-12 text-center d-inline-flex align-self-center">
                            <div className="myLegend mx-auto">
                                <h4 className="clr1">New = {this.state.MemberCount}</h4>
                                <h4 className="clr2 color">Total = {this.state.AllMemberCount}</h4>
                            </div>
                        </div>
                        <div className="W_M_Y_box P_15_T col-md-3 col-xs-12 pb-3 d-inline-flex align-self-center">
                            <div className="vw_bx12 mx-auto">
                                <h5><b>View By</b></h5>
                                <span onClick={() => this.memberCountBy('week')} className={this.state.view_type == 'week' ? 'color' : ''}>Week</span><br />
                                <span onClick={() => this.memberCountBy('month')} className={this.state.view_type == 'month' ? 'color' : ''}>Month </span><br />
                                <span onClick={() => this.memberCountBy('year')} className={this.state.view_type == 'year' ? 'color' : ''}> Year</span>
                            </div>
                        </div>
                    </div>
                </li> */}
            </React.Fragment>
        );
    }
}
