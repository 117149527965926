import React, { Component } from "react";
import DataTableListView from '../oncallui-react-framework/view/ListView/DataTableListView';
import { CustomModal, Form, Row, SelectList } from '../oncallui-react-framework';
import { PageHeaderControl, Button, Icon } from '@salesforce/design-system-react';
import { css, checkLoginWithReturnTrueFalse, getPermission, postImageData, toastMessageShow, handleRemoveShareholder, postData, handleShareholderNameChange, AjaxConfirm } from 'service/common';
import jQuery from "jquery";
import Col from '../oncallui-react-framework/grid/Col';
import { Editor } from 'react-draft-wysiwyg';
import { TollBarConfig } from './templates/TollBarConfig';
import CustomDropdownPlaceHolder from './templates/CustomDropdownPlaceHolder';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, Modifier } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../scss/components/admin/crm/pages/sales/EmailActivity.scss';
import { toast } from 'react-toastify';
import { stateFromHTML } from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import EmailInsertMergeField from '../Activity/EmailInsertMergeField';
class TemplateListing extends Component {
    /**
     * default displayed columns in the listing
     */
    static defaultProps = {
        displayed_columns: {
            'name': true,
            'description': true,
            'subject': true,
            'folder': true,
            'status': true,
            'archive': true,
            'created_at': true,
            'created_by': true,
            "actions": true
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            searchVal: '',
            filterVal: '',
            taskList: [],
            filter_status: 'all',
            openCreateModal: false,    
            refreshTable: false,
            displayed_columns: Object.keys(props.displayed_columns).filter(k => !!props.displayed_columns[k]),
            editorState: EditorState.createEmpty(),
            attachments: [],
            existing_attachment: [],
            footer: "<p><br /></p><p><br /></p><p><br /></p><p><u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></p><p>660 Canterbury Rd, Surrey Hills VIC 3127, Australia</p><p><a href='https://www.oncall.com.au'>www.oncall.com.au</a></p><p>(03) 9896 2468</p>",
            merge_field_sep_start: '{{{',
            merge_field_sep_end: '}}}'
        };
        this.permission = (checkLoginWithReturnTrueFalse()) ? ((getPermission() == undefined) ? [] : JSON.parse(getPermission())) : [];
        this.reactTable = React.createRef();
        this.rootRef = React.createRef();
        this.newFormRef = React.createRef()
    }

    custom_validation = () => {
        var return_var = true;
        var state = {};

        if (this.state['content'] == undefined || this.state['content'] == '') {
            state['content' + '_error'] = true;
            this.setState(state);
            return_var = false;
        }
        return return_var;
    }

    uploadHandler = (file) => {
        this.setState({ upload_loading: true })
        const formData = new FormData()
        formData.append('file', file)

        postImageData('imail/Templates/upload_template_attachment_tempory', formData, this).then((result) => {
            if (result.status) {

                var attachments = this.state.attachments;
                attachments[attachments.length - 1]['updated_filename'] = result.filename;
                this.setState({ attachments: attachments })
            } else {
                toastMessageShow(result.error, 'e');
            }
            this.setState({ upload_loading: false })
        })
    }

    getTemplateDetails = (id) => {
        postData('imail/Templates/details', { id: id }).then((result) => {
            if (result.status) {
                let result_data = result.data;
                let content = result_data.content || "";
                // Convert the raw html to preview format
                let contentState;
                let editorState = EditorState.createEmpty();
                let contentBlock = htmlToDraft(content);
                if (contentBlock) {
                    // Convert the raw html to preview format
                   contentState = stateFromHTML(result_data.content);
                   // Editor content
                   editorState = EditorState.createWithContent(contentState);
                    this.setState({ editorState });
                } else {
                    // Convert the raw html to preview format
                    contentState = stateFromHTML(result_data.content);
                    // Editor content
                    editorState = EditorState.createWithContent(contentState);
                }
                
                this.setState({
                    ...result_data,
                    editorState: editorState
                });
            }
        });
    }

    showArchiveModal(id) {
        const msg = `Are you sure you want to archive this email template?`
        const confirmButton = `Archive Email Template`
        AjaxConfirm({ template_id: id }, msg, `imail/Templates/archive_template`, { confirm: confirmButton, heading_title: `Archive Email Template` }).then(result => {
            if (result.status) {
                this.setState({refreshTable: !this.state.refreshTable});
                toastMessageShow(result.msg, "s");
            } else {
                if (result.error) {
                    toastMessageShow(result.error, "e");
                }
                return false;
            }
        })
     }

    showModal = (selected, action) => {
        if(action == 'edit' || action == 'create'){
            if (action === "edit") {
                this.setState({ add_modal: true, selected_id : selected.id, is_edit: true });
                this.getTemplateDetails(selected.id);
            } else {
                this.setState({ add_modal: true, selected_id : false, is_edit: false });
            }         
        }
        if(action == 'delete'){
            this.showArchiveModal(selected.id);
        }
 
    }

    /**
     * setting the column headers in the listing table
     */
    determineColumns() {
        return [
           {
                _label: 'Name',
                accessor: "name",
                id: 'name'
            },
            {
                _label: 'Description',
                accessor: "description",
                id: 'description'
            },
            {
                _label: 'Folder',
                accessor: "folder",
                id: 'folder',
                callback: (value) => {return value && value.charAt(0).toUpperCase() + value.slice(1);}
            },
            {
                _label: 'Status',
                accessor: "status",
                id: 'status',
                callback: value => {return value === "1"? "Active" : "Archive"}
            },
            {
                _label: 'Archive',
                accessor: "archive",
                id: 'archive',
                callback: value => {return value === "1"? "Yes" : "No"}
            },
            {
                _label: 'Created Date',
                accessor: "created_at",
                id: 'created_at'
            },
            {
                _label: 'Created By',
                accessor: "created_by",
                id: 'created_by',
            },
            {
                _label: '',
                accessor: "actions",
                id: 'actions',
                sortable: false,
                width: '50px',
                actionList : [
                    {
                        id: 0,
                        label: 'Edit',
                        value: '1',
                        key: 'edit',
                    },
                    {
                        id: 1,
                        label: 'Delete',
                        value: '2',
                        key: 'delete',
                    }
                ],
                callback: (actionList, item) => {
                    if (item.archive === "1") {
                        return []
                    }
                    return actionList;
                }
             }
        ]
    }

    handleOnRenderActions() {
        return (
            <React.Fragment>
                <PageHeaderControl>
                    <Button label="New" onClick={e => this.showModal(null, "create")} />
                </PageHeaderControl>
            </React.Fragment>
        )
    }

    render() {
        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
            }
        })
        return (
            <React.Fragment>
                <div className="ListContact slds td_height_settings" style={styles.root} ref={this.rootRef}>
                    <DataTableListView
                        page_name="Email Templates"
                        header_icon="template"
                        displayed_columns={this.props.displayed_columns}
                        filter_options={
                            [
                                { value: "Folder", label: "Folder", field:"folder"},
                                { value: "Status", label: "Status", field:"status"}
                            ]
                        }
                        list_api_url="imail/Templates/template_list"
                        related_type="email_templates"
                        filter_status="all"
                        default_filter_logic="1 AND 2"
                        filter_title="Email Templates"
                        show_filter={false}
                        check_default="all"
                        determine_columns={this.determineColumns}
                        on_render_actions={() => this.handleOnRenderActions()}
                        filtered={true}
                        sortColumnLabel="ID"
                        sortColumn="id"
                        refresh={this.state.refreshTable}
                        showModal={this.showModal}
                    />
                    {
                        this.state.add_modal && 
                        <CustomModal
                            title={this.state.selected_id && "Update Template" || "New Template"}
                            ok_button="Save"
                            showModal={this.state.add_modal}
                            setModal={(status) => this.closeModal(status)}
                            onClickOkButton={(e) => this.handleOnSubmit(e)}
                            size="medium"
                            style={{ overFlowY: "hidden" }}
                            loading={this.state.uploading || this.state.loading}
                        >
                            {this.renderNewTemplateForm()}
                        </CustomModal>
                    }
                </div>
            </React.Fragment>
        );
    }

    /**
     * @param {React.FormEvent} e
     */
     handleOnSubmit = async e => {
        if (e) e.preventDefault()
        var validator = jQuery("#template_form").validate({});
        var is_validate = this.custom_validation();

        if (!this.state.loading && jQuery("#template_form").valid() && is_validate) {
            this.setState({ loading: true, }, () => {                
                let reqData = {
                    loading: this.state.loading,
                    previousTitle: this.state.previousTitle,
                    is_edit: this.state.is_edit,
                    name: this.state.name,
                    content: this.state.content,
                    dynamic_field_name: this.state.dynamic_field_name,
                    attachments: this.state.attachments,
                    existing_attachment: this.state.existing_attachment,
                    from: this.state.from,
                    subject: this.state.subject,
                    template_id: this.state.id,
                    id: this.state.id,
                    folder: this.state.folder || "private",
                    description: this.state.description
                };
                postData('imail/Templates/create', reqData).then((result) => {
                    if (result.status) {
                        toastMessageShow(result.msg, "s");
                        this.setState({ refreshTable: !this.state.refreshTable });
                        this.closeModal(false);
                    } else {
                        toastMessageShow(result.error, 'e');
                    }
                    this.setState({ loading: false });
                });
            });
        }
        else {
            this.setState({ validate: true });
            validator.focusInvalid();
        }
    }

    selectAttchment = (event) => {
        var allowExtentsion = ['jpg', 'jpeg', 'png', 'xlx', 'xls', 'doc', 'docx', 'pdf', 'odt', 'rtf'];
        var attachments = this.state.attachments;

        var selectedFile = event.target.files[0];
        var filename = event.target.files[0].name;
        var size = event.target.files[0].size / 1024 / 1024; // in mb convert

        var ext = filename.replace(/^.*\./, '');
		ext = ext.toLowerCase();
		
        if (allowExtentsion.includes(ext)) {
            if (size > 5) {
                toastMessageShow("Max file dize allow only 5 mb", 'e');
            } else {
                this.setState({ attachments: [...attachments, { filename: filename }] }, () => {
                    this.uploadHandler(selectedFile);
                });
            }
        } else {
            this.extensionError();
        }
    }

    extensionError = () => {
        var error = <p>Sorry we are only supported <br /> jpg, jpeg, png, xlx, xls, doc, docx, pdf, odt, rtf</p>
        toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });
    }

    setEditorContent(content = "") {
        // Convert the raw html to preview format
        let contentState = stateFromHTML(this.state.footer);
        if (content) {
            contentState = stateFromHTML(content);
        }
        // Editor content
        let editorState = EditorState.createWithContent(contentState);
        // Update content state for footer
        const markup = stateToHTML(editorState.getCurrentContent());
        this.setState({ editorState, content: markup });
    }

    componentDidMount() {
        this.setEditorContent();
    }

    insertText = (text, editorState) => {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text
        );

        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    }

    renderNewTemplateForm() {
        return (
            <>
            <Form paddingBottom={1} id="template_form" ref={this.newFormRef}>
                <Row>
                    <Col id="template-name" input={{ id: "template-name", name: "name", label: "Template Name", required: true, value: this.state.name, onChange: (e) => this.setState({ name: e.target.value }) }}
                    />
                </Row>
                <Row>
                    <Col id="template-description" input={{ id: "template-description", name: "description", label: "Template Description", value: this.state.description, onChange: (e) => this.setState({ description: e.target.value }) }}
                    />
                </Row>
                <Row>
                    <Col id="template-from-label" input={{ id: "from-label", name: "from", label: "From Label", required: true, value: this.state.from, onChange: (e) => this.setState({ from: e.target.value }) }}
                    />
                </Row>
                <Row>
                    <Col id="template-subject" input={{ id: "subject", name: "subject", label: "Subject", required: true, value: this.state.subject, onChange: (e) => this.setState({ subject: e.target.value }) }}
                    />
                </Row>
                <Row>
                    <SelectList
                        id="template-folder"
                        required
                        label="Folder"
                        options={[
                            { label: "Public", value: "public" },
                            { label: "Private", value: "private" }
                        ]}
                        onChange={value => this.setState({ folder: value })}
                        value={this.state.folder || "private"}
                        name="folder"
                    />
                </Row>
                <Row>
                    <Col>
                    <Editor
                        ref={this.setEditor}
                        toolbar={TollBarConfig}
                        editorState={this.state.editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorChange}
                        toolbarCustomButtons={[<CustomDropdownPlaceHolder />]}
                    />
                    </Col>
                </Row>
                <Row>
                <div className="row py-1 px-2">
                    <div className="px-0 py-0 mt-2 d-inline-block attach_bn">
                        <span className="upload_btn d-inline-block">
                            <label className="btn btn-default btn-sm center-block btn-file">
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="attachment" className="MSG_Tooltip" placement={'top'}>Attach File</Tooltip>}><i className="icon icon-attach-im attach_im mr-0"></i></OverlayTrigger>
                                <input className="p-hidden" multiple type="file" onChange={this.selectAttchment} name="attachment" value="" />
                            </label>
                        </span>
                    </div>
                    <div className="px-0 py-0 mt-2 ml-1 d-inline-block attach_bn">
                        <button style={{margin:"7px 6px 6px 6px"}} class="slds-button slds-button_icon" tabindex="5" onClick={this.triggerInsertMergeField} id="insert-merge-fields-btn">
                            <Icon
                                assistiveText={{ label: 'Lead' }}
                                category="utility"
                                name={`merge_field`}
                                size={'xx-small'}
                            />
                            <span class="slds-assistive-text">Insert Merge Field</span>
                        </button>
                    </div>                    
                </div>
                {this.state.existing_attachment.map((val, index) => {
                    if (val.is_deleted) {
                        return false;
                    }
                    return <div key={index + 1} className="attach_txt"><span>{val.filename}</span>
                        <i onClick={(e) => handleShareholderNameChange(this, "existing_attachment", index, "is_deleted", true, e)} className="icon icon-cross-icons-1"></i>
                    </div>
                })}
                {this.state.attachments.map((val, index) => (
                    <div key={index + 1} className="attach_txt pt-2"><span>{val.filename}</span><i onClick={(e) => { handleRemoveShareholder(this, e, index, 'attachments').then(() => {
                        this.handleExistingAttachment(e, index);
                    }); }} className="icon icon-cross-icons-1"></i> </div>
                ))}
                </Row>
            </Form>
            {
                this.state.merge_field_modal && (
                    <EmailInsertMergeField
                        setMergeFieldValus={this.setMergeFieldValues.bind(this)}
                        showModal={this.state.merge_field_modal}
                        closeModal={this.closeModal}
                        headingTxt="Insert Merge Field"
                        {...this.props}
                    />
                )
            }
            </>
        )
    }

    setMergeFieldValues = (value) => {
        this.setState({ merge_field_value: value, merge_field_modal: false });
        if (value != '' && value != undefined) {
            let text = this.state.merge_field_sep_start + value + this.state.merge_field_sep_end;
            this.sendTextToEditor(text);
        }
    }

    sendTextToEditor = (text) => {
        this.setState({ editorState: this.insertText(text, this.state.editorState) });
        this.focusEditor();
    }

    focusEditor = () => {
        if (this.editor) {
            this.editor.focusEditor();
        }
    };

    setEditor = (editor) => {
        this.editor = editor;
    };

    handleExistingAttachment(e, index) {
        e.preventDefault();
        let eas = this.state.existing_attachment;
        if (eas) {
            let ea = eas[index];
            ea["is_deleted"] = 1;
            eas[index] = ea;
            this.setState({ existing_attachment: eas });
        }
    }

    /**
     * Open Merge Insert Field
     * @param {eventObj} e 
     */
     triggerInsertMergeField = (e) => {
        e.preventDefault();
        this.setState({ merge_field_modal: true });
    }

    onEditorChange = (editorState) => {
        const markup = stateToHTML(editorState.getCurrentContent())
        this.setState({ editorState, content: markup });
    };

    closeModal(status) {
        this.setEditorContent("");
        this.setState({ add_modal: status, selected_id:0, name: "", content: "", description: "", folder:"", from:"", id: "", subject:"", template_id:"", is_edit:false, attachments: [] });
    }
}

export default TemplateListing;