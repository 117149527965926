import React from 'react'
import _ from 'lodash'
import jQuery from 'jquery'

import { postData, toastMessageShow, css } from '../../../service/common'

/**
 * Component to display form to configure see api credentials
 */
class SettingsApiSeek extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            isSubmitting: false,
            seekUsername: '',
            seekPassword: '',
            seekAdvertiserName: '',
            seekAdvertiserId: '',
        }

        /**
         * Will be used by jQuery validation
         * 
         * @type {React.Ref<HTMLFormElement>}
         */
        this.formEl = React.createRef()

    }

    // Load the configurations when component is mounted
    async componentDidMount() { 
        this.setState({ loading: true })

        try {
            const { status, data } = await postData('admin/settings/seek_settings')
            if (status) {
                this.setState(data);
            }
        } catch (e) {
            console.error(e)
        } finally {
            this.setState({ loading: false });
        }
    }

    /**
     * Handles client side validation and form submission. 
     * Will warn the user before submitting as this is a very dangerous operation
     * 
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    handleOnSubmit = async e => {
        e.preventDefault();

        // validate, exit early if not valid
        jQuery(this.formEl.current).validate()
        if ( ! jQuery(this.formEl.current).valid()) {
            return
        }

        // Warn the user. Let's use the browser-native confirm dialog
        const confirmSave = window.confirm('Are your sure you want to make changes to your Seek configuration? We recommend backing up your Seek credentials before proceeding.');
        if (!confirmSave) {
            return
        }

        // Submit
        this.setState({ isSubmitting: true })

        const reqData = {
            seekUsername: this.state.seekUsername,
            seekPassword: this.state.seekPassword,
            seekAdvertiserName: this.state.seekAdvertiserName,
            seekAdvertiserId: this.state.seekAdvertiserId,
        }

        try {
            const res = await postData('admin/settings/save_seek_settings', reqData)

            if (res.status) {
                toastMessageShow(res.msg, 's')
            } else {
                toastMessageShow(res.error, 'e')
            }

        } catch (e) {
            console.error(e)
        } finally {
            this.setState({ isSubmitting: false })
        }
    }

    
    render() {
        // TODO: Currently contains copied and pasted code from UpdatePassword.js 
        // resulting will bunch of misleading classnames

        const { loading, isSubmitting } = this.state

        const styles = css({
            headingParent: {
                marginTop: 70,
                marginBottom: 70
            },
            inputGroupsWrapperParent: {
                margin: 0,
            },
            inputGroupsWrapper: {
                maxWidth: 640,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            saveBtn: {
                maxWidth: 345,
            }
        })


        return (
            <form ref={this.formEl} onSubmit={this.handleOnSubmit} noValidate>
                <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center Pass_Ma1" style={styles.headingParent}>
                        <h3>Seek Settings</h3>
                    </div>
                    <div className="col-xs-12">
                        <div className="limiter">
                            {/* misleading classname 'login_1' */}
                            <div className="login_1" style={styles.inputGroupsWrapperParent}>
                                <div className="col-xs-12" style={styles.inputGroupsWrapper}>

                                    <div className="input_2">
                                        <input type="text" 
                                            className="input_3"
                                            name="seekUsername" 
                                            id="Seek-username" 
                                            placeholder="Seek Username"
                                            value={this.state.seekUsername} 
                                            onChange={e => this.setState({ seekUsername: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="input_2">
                                        <input type="password" 
                                            className="input_3"
                                            name="seekPassword" 
                                            id="Seek-password" 
                                            placeholder="Seek Password"
                                            value={this.state.seekPassword} 
                                            onChange={e => this.setState({ seekPassword: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="input_2">
                                        <input type="text" 
                                            className="input_3"
                                            name="seekAdvertiserName" 
                                            id="Seek-advertiser_name"
                                            placeholder="Advertiser Account Name"
                                            value={this.state.seekAdvertiserName} 
                                            onChange={e => this.setState({ seekAdvertiserName: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="input_2">
                                        <input type="text" 
                                            className="input_3"
                                            name="seekAdvertiserId" 
                                            id="Seek-advertiser_id"
                                            placeholder="Advertiser Account ID"
                                            value={this.state.seekAdvertiserId} 
                                            onChange={e => this.setState({ seekAdvertiserId: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="col-md-10 col-md-offset-1">
                                        <div className="login_but">
                                            {/* misleading classname 'but_login orange'. Class name is 'orange' but the button is colored as violet */}
                                            <button disabled={loading || isSubmitting} className="but_login orange" title={`DANGER: Back-up your credentials before saving!`} style={styles.saveBtn}>
                                                {isSubmitting ? `Saving` : `Save`}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

}

export default SettingsApiSeek