const initaileState = {
    is_fetching: false,
    is_details_fetching: false,
    mail_listing: [],
    mail_content_listing: {content: []},
    reply_mail: false,
    create_mail: false,
    mail_type: "",
}

const externalImailData = (state = initaileState, action) => {

    switch (action.type) {
        case 'set_external_imail_message_listing':
            return {...state, mail_listing: action.mail_data, is_fetching: false};
            
        case 'set_external_imail_all_content':
            return {...state, mail_content_listing: action.mail_data, reply_mail: false, create_mail: false, is_details_fetching: false};
            
        case 'update_external_mail_content_value':

            var mail_content_listing = state.mail_content_listing;
            
            var updatedData = {...mail_content_listing, ...action.update_data};
            return {...state, mail_content_listing: updatedData};
            
        case 'update_external_mail_listing_value':
              var mail_listing = state.mail_listing;
              var index = mail_listing.findIndex(x => x.id == action.messageId)
              
              if(index >= 0){
                  var tempData = {...mail_listing[index], ...action.update_data};
                  mail_listing[index] = tempData;
                  
                  return {...state, mail_listing: JSON.parse(JSON.stringify(mail_listing))};
              } 
              
        case 'reply_mail_of_external_mail':
            return {...state, reply_mail: action.status, replyMailId: action.messageId};
            
            
         case 'set_external_mail_type':
            return {...state, mail_type: action.mail_type};
            
            
         case 'remove_external_mail_from_listing':
              var mail_listing = state.mail_listing;
              mail_listing = mail_listing.filter((s, sidx) => action.messageId !== s.id);
           
              return {...state, mail_listing: JSON.parse(JSON.stringify(mail_listing))};
            
          case 'compose_new_external_mail':
              return {...state, create_mail: action.status};
              
          case 'set_external_mail_fetching_status':
              return {...state, is_fetching: action.value};  
          
           case 'set_external_mail_details_fetching_status':
              return {...state, is_details_fetching: action.value};
          
        default:
            return state;
    }
}

export default externalImailData
    