import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';
import { postData, toastMessageShow } from 'service/common'

class CustomDropdownPlaceHolder extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  }

  state = {
    open: false,
    dynamic_field_name: [],
  }

  componentDidMount() {
    this.getDynamicFieldName();
  }


  openPlaceholderDropdown = () => this.setState({ open: !this.state.open })

  addPlaceholder = (placeholder) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

  getDynamicFieldName = () => {
    postData('imail/Templates/get_dynamic_email_field_name', {}).then((result) => {
      if (result.status) {
        this.setState({ dynamic_field_name: result.data });
      } else {
        toastMessageShow(result.error, 'e');
      }
    });
  }

  render() {
    return (
      <div onClick={this.openPlaceholderDropdown} className="rdw-block-wrapper" aria-label="rdw-block-control">
        <div className="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown">
          <div className="rdw-dropdown-selectedtext" title="Placeholders">
            <span>Select Item</span>
            <div className={`rdw-dropdown-caretto${this.state.open ? "close" : "open"}`}></div>
          </div>
          <ul className={`rdw-dropdown-optionwrapper ${this.state.open ? "" : "placeholder-ul"}`}>
            {this.state.dynamic_field_name.map((item, index) => (
              <li
                onClick={this.addPlaceholder.bind(this, item.value)}
                key={index +1}
                className="rdw-dropdownoption-default placeholder-li"
              >{item.label}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default CustomDropdownPlaceHolder;