import React from 'react';
import { connect } from 'react-redux';
import { ROUTER_PATH } from 'config.js';
import { Link } from 'react-router-dom';


class RemoteAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-lg-12">
                        <div className=" py-4">
                            <span className="back_arrow ">
                                <Link to={ROUTER_PATH + "admin/helpdesk"}><span className="icon icon-back1-ie"></span></Link>
                            </span>
                        </div>


                        <div className="by-1 ">
                            <div className="row d-flex  py-4">
                                <div className="col-lg-12 col-md-2">
                                    <div className="h-h1 color">Remote Access</div>
                                </div>

                            </div>
                        </div>


                        <div className={'Bg_F_moule mt-5'}>

                            <div className={'row d-flex justify-content-center'}>
                                <div className={'col-lg-7'}>
                                    <p>Generate a pin for HCM Tech support to gain remote access. Please ensure that you only supply remote access to HCM Tech support or HCM admin staff.</p>

                                    <div className={'row mt-5'}>
                                        <div className={'col-lg-3 col-md-4'}>
                                            <div className="btn cmn-btn1 btn-block inpHeight_btn">Generate Token</div>
                                        </div>
                                        <div className={'col-lg-9 col-md-8'}>
                                            <input type="text" placeholder={'123-123-123-123-123'} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>


            </React.Fragment>
        );
    }

}

export default connect(null, null)(RemoteAccess);