import React, { Component } from 'react';
import { ROUTER_PATH, PAGINATION_SHOW } from '../../../config.js';
import OrganisationAddSitePopUp from './OrganisationAddSitePopUp';
import { checkItsNotLoggedIn, postData, handleChangeChkboxInput } from '../../../service/common.js';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import Pagination from "../../../service/Pagination.js";
import { setActiveSelectPage } from './actions/OrganisationAction.js';
import { OrganisationPageIconTitle } from 'menujson/pagetitle_json';
import { connect } from 'react-redux'

class OrganisationTextDashboard extends Component {
    constructor(props) {
        checkItsNotLoggedIn(ROUTER_PATH);
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.props.setActiveSelectPage('org_dashboard_listing');
    }

    render() {

        return (

                    <div className="row _Common_He_a" style={{marginTop:'120px'}}>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                            <h2 className="color">Would you like to create your first Organisation? </h2>
                            <div className="my-5" style={{fontSize:'18px'}}>
                            You don't seem to have any Organisations in your system. Orgs are separte entities that require
                        <br />
                            your staffing services from time to time. Set up an Org so you can start receiving shift requests!
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="col-md-5 d-flex">
                                <Link className="btn-1 w-50 mr-2" to={'createOrganisation'}>Create New Organisation</Link>
                                <Link to={'/admin/house/dashboard'} className="btn-1 out_line w-50  ml-2">We have our own houses</Link>
                                </div>
                            </div>
                            <div className="my-5">
                                Not sure how to start ?  Click <a href="#">here</a> for help
                            </div>
                        </div>

                    </div>

        );
    }
}
//
const mapStateToProps = state => ({
    //ActiveClass : state.OrganisationReducer.ActiveClassProfilePage,
    //    OrganisationProfile : state.OrganisationReducer.orgProfile
})

const mapDispatchtoProps = (dispach) => {
    return {
        setActiveSelectPage: (value) => dispach(setActiveSelectPage(value)),
    }
}
export default connect(mapStateToProps, mapDispatchtoProps)(OrganisationTextDashboard)