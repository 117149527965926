const initialState = {
  memberProfile: {
   full_name:'',
   ocs_id:'',
   first_name:'',
   phone_ary:[]
},
activePage:{pageTitle:'',pageType:''}
}


const MemberProfileData = (state = initialState, action) => {
  switch (action.type) {
    case 'set_member_profile_data':
    return { ...state, memberProfile: action.profileData };

    case 'set_active_class_member':
    return { ...state, ActiveClassProfilePage: action.value };

    case 'set_active_page_member':
    return {...state, activePage: action.value};
    
    case 'member_update_profile_record':
    var obj1  = state.memberProfile;
    var obj2 = action.profileData;          
    var clonedObj = { ...obj1 };
    var mergedObj = { ...obj1, ...obj2 };
            //return { ...state, profileData: mergedObj };
            return { ...state,memberProfile: mergedObj};

            default:
            return state
        }
    }



    export default MemberProfileData

