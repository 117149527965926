import React, { Component } from 'react';
import {  siteBillPayBy,orgTax,orgGst } from '../../../dropdown/Orgdropdown.js';
import jQuery from "jquery";
import { BASE_URL } from '../../../config.js';
import { postData, getOptionsSuburb, handleChangeChkboxInput, handleChangeSelectDatepicker, handleShareholderNameChange, handleAddShareholder, postImageData, selectFilterOptions, getStateList, handleRemoveShareholder, queryOptionData,googleAddressFill,googeAddressHandler,onKeyPressPrevent } from '../../../service/common.js';
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import Modal from 'react-bootstrap/lib/Modal'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux'
import { setOrgBookingData } from './actions/OrganisationAction.js';
import { ToastUndo } from 'service/ToastUndo.js';
import SimpleBar from 'simplebar-react';
import ReactGoogleAutocomplete from './../externl_component/ReactGoogleAutocomplete';

var empty = require('is-empty');

const getOrganisation = (input) => {
    return queryOptionData(input, 'common/Common/get_org_name', { query: input }, 2);
}

const key_contact_ary = { firstname: '', 'lastname': '', 'position': '', 'department': '', 'sitePh': [{ 'phone': '' }], 'siteMail': [{ 'email': '' }] };
const billing_contact_ary = { firstname: '', 'lastname': '', 'position': '', 'department': '', 'sitePh': [{ 'phone': '' }], 'siteMail': [{ 'email': '' }],'payroll_tax':'','gst':'' };

class OrganisationUpdatePopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SitePhone: [{ 'phone': '' }],
            SiteEmail: [{ 'email': '' }],
            keyContactDetails: [JSON.parse(JSON.stringify(key_contact_ary))],
            billingContactDetails: [JSON.parse(JSON.stringify(billing_contact_ary))],
            loading: false,
            bill_pay_by: '',
            site_status: 1,
            completeAddress: [{ 'street': '', 'city': '', 'state': '', 'postal': '' }],
        }
    }

    selectChange = (selectedOption, fieldname) => {
        var state = {};
        state[fieldname] = selectedOption;
        if (fieldname == 'city' && selectedOption)
            state['postal'] = state.city.postcode;

        this.setState(state);
    }

    handleAddHtml = (e, tagType, object_array) => {
        e.preventDefault();
        var state = {};
        var list = this.state[tagType];
        var x = JSON.parse(JSON.stringify(key_contact_ary));
        state[tagType] = list.concat([x]);
        this.setState(state);
    }

    handleRemoveHtml = (e, idx, tagType) => {
        e.preventDefault();
        var state = {};
        var List = this.state[tagType];
        state[tagType] = List.filter((s, sidx) => idx !== sidx);
        this.setState(state);
    }

    componentDidMount() {
        let otherObject = {}
        //console.log(this.props);
        //console.log('selectedData',this.props.selectedData);

        if (!empty(this.props.selectedData) && this.props.hasOwnProperty('selectedData') && this.props.selectedData) {
            let keyContactDetails = this.props.selectedData.siteDetails.filter((row) => row.type == 3);
            let billingContactDetails = this.props.selectedData.siteDetails.filter((row) => row.type == 4);

            //console.log('billingContactDetails',billingContactDetails);
            if (keyContactDetails.length > 0) {
                otherObject['keyContactDetails'] = keyContactDetails;
            }
            if (billingContactDetails.length > 0) {
                otherObject['billingContactDetails'] = billingContactDetails;
            }
        }
        this.setState({ ...this.props.selectedData, ...otherObject }, () => {

            let stateadd = {};

            if (this.props.hasOwnProperty('site_id') && this.props.site_id != null && this.props.site_id != '') {
                stateadd['site_id'] = this.props.site_id;
            }

            if (this.props.hasOwnProperty('mode') && this.props.mode != null && this.props.mode != '') {
                stateadd['mode'] = this.props.mode;
            }

            if (Object.keys(stateadd).length > 0) {
                this.setState(stateadd);
            }
        });

        this.setState({ orgId: this.props.orgId, callType: this.props.callType });
        getStateList().then((result) => {
            var stateList = result;
            this.setState({ stateList: stateList }, () => { });
        });

       // console.log(this.state.completeAddress)
    }

    onSubmit = (e) => {
        e.preventDefault()
        jQuery('#updateAddress').validate({ ignore: [] });
        if (jQuery('#updateAddress').valid()) {
            this.setState({ loading: true }, () => {
                postData('organisation/OrgDashboard/save_site', JSON.stringify(this.state)).then((result) => {
                    if (result.status) {
                        toast.success(<ToastUndo message={result.msg} showType={'s'} />, {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true
                        });
                        this.closeModal();
                    }
                    else {
                        toast.error(<ToastUndo message={result.error} showType={'e'} />, {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true
                        });
                    }
                    this.setState({ loading: false })
                });
            });
        }
    }

    orgOnChange = (e) => {
        var state = {};
        state['organisation_name'] = e;
        state['organisation_abn'] = e.abn;
        state['organisation_id'] = e.value;
        this.setState(state, () => { 
            this.getOrgBillingDetail(e.value);
        });
    }

    getOrgBillingDetail = (org_id) => {
        postData('organisation/Organisation/get_org_billing_detail', { parentOrg: org_id }).then((result) => {
            if (result.status && result.data) {
                var temp_state = [result.data];
                this.setState({billingContactDetails : temp_state,bill_pay_by:1});
            }
        });
    }

    closeModal = () => {
        this.props.closeModal();
    }

    handleShareholderNameChange = (stateName, index, fieldName, value, e) => {
        handleShareholderNameChange(this, stateName, index, fieldName, value, e)
    }

    handleChangeSelectDatepicker = (selectedOption, fieldname) => {
        handleChangeSelectDatepicker(this, selectedOption, fieldname)
        if(selectedOption == 3 && fieldname == 'bill_pay_by')
        {
            this.setState({billingContactDetails: [JSON.parse(JSON.stringify(billing_contact_ary))]})
        }
        
        if(this.props.callType == 0)
            var myOrgId = this.props.orgId;  //calling from site listing page and org id from url
        else
            var myOrgId = this.state.organisation_id; // calling from dashboard,create site and org id from selected org

        if(selectedOption == 1 && fieldname == 'bill_pay_by'){
            this.getOrgBillingDetail(myOrgId);
        }
    }

    handleAddShareholderDepth = (e, stateName, object_array, main_obj, idxx, input_type) => {

        e.preventDefault();
        var state = {};
        var list = this.state[stateName];

        var list_temp = list[idxx][main_obj];

        if (input_type == 'phone')
            list_temp = list_temp.concat([{ 'phone': '' }]);
        else
            list_temp = list_temp.concat([{ 'email': '' }]);

        list[idxx][main_obj] = list_temp;
        state[stateName] = list;
        this.setState(state);
    }

    handleRemoveShareholderDepth = (e, index, stateName, main_obj, idxx, input_type) => {
        e.preventDefault();
        var state = {};
        var List = this.state[stateName];
        var list_temp = List[idxx][main_obj];
        var removedata = list_temp.filter((s, sidx) => index !== sidx);
        List[idxx][main_obj] = removedata;
        state[stateName] = List;
        this.setState(state);
    }

    handleShareholderNameChangeDepth = (main_incr, incr, fieldName, value, stateName) => {
        var state = {};
        var List = this.state[stateName];
        if (fieldName == 'phone')
            List[main_incr]['sitePh'][incr][fieldName] = value
        else
            List[main_incr]['siteMail'][incr][fieldName] = value

        state[stateName] = List;
        this.setState(state);
    }

    handleGoogleAddressChange = (index, stateKey, fieldtkey, fieldValue) => {
        //console.log(fieldtkey, fieldValue)
        var state = {};
        var tempField = {};
        var List = this.state[stateKey];
        List[index][fieldtkey] = fieldValue

        if (fieldtkey == 'state') {
            List[index]['city'] = ''
            List[index]['suburb'] = ''
            List[index]['postal'] = ''
            List[index]['state_error'] = false;
        }

        if (fieldtkey == 'city' && fieldValue) {
            List[index][fieldtkey] = fieldValue.value;
            List[index]['postal'] = fieldValue.postcode;
            List[index]['suburb'] = fieldValue.value;
        }

        state[stateKey] = List;
        this.setState(state,()=>{
            
        });
    }

    render() {

        return (
            <Modal
                className="Modal fade Modal_A Modal_B Org_Module O_Modal_size add_new_site_MODAL"
                show={this.props.modal_show}
                container={this}
                aria-labelledby="contained-modal-title"
            >

                <Modal.Body>
                    <form id="updateAddress" onKeyPress={onKeyPressPrevent}>
                        <div className="dis_cell-1">
                            <div className="text text-left by-1 Popup_h_er_1">{this.props.title}:
                        <a onClick={() => this.closeModal()} className="close_i"><i className="icon icon-cross-icons"></i></a>
                            </div>


                            <SimpleBar style={{ maxHeight: '670px', overflowX: 'hidden', paddingLeft: '15px', paddingRight: '15px' }} forceVisible={false}>

                                {(this.state.callType && this.state.callType == 1) ?
                                    <div className="row P_25_T">
                                        <div className="w-40-lg col-lg-4 col-sm-4">
                                            <label className="label_2_1_1">Organisation Name (Legal):</label>
                                            <div className="search_icons_right modify_select modify_select_big">
                                                <Select.Async
                                                    name="organisation_name"
                                                    loadOptions={(e) => getOrganisation(e)}
                                                    clearable={false}
                                                    placeholder='Search'
                                                    value={this.state.organisation_name}
                                                    onChange={(e) => this.orgOnChange(e)}
                                                    className="default_validation search_icons_right modify_select"
                                                    filterOptions={selectFilterOptions}
                                                    cache={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    : ''}


                                <div className="row mt-5">
                                    <div className="col-lg-12">
                                        <div className={(this.state.callType && this.state.callType == 1) ? 'pAY_heading_01 bt-1' : 'pAY_heading_01'}><div className="tXT_01"> New Site - Details:</div></div>
                                    </div>
                                </div>

                                <div className="row P_15_T" >
                                    <div className="w-20-lg col-lg-2 col-sm-3">
                                        <label className="label_2_1_1">Title: </label>
                                        <span className="required">
                                            <input placeholder="Title" className="text-left" type="text" name={'title'} value={this.state.title} data-rule-required="true" autoComplete="new-password" onChange={(e) => handleChangeChkboxInput(this, e)} />
                                        </span>
                                    </div>

                                    <div className="w-20-lg col-lg-2 col-sm-3">
                                        {this.state.SitePhone.map((value, idx) => (
                                            <div className="mb-4" key={idx + 1}>
                                                <label className="label_2_1_1">Phone ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                                <span className="required">
                                                    <div className="input_plus__ ">
                                                        <input type="text" className="input_f distinctPh" placeholder="Can Include Area Code" value={value.phone} name={'org_phone_' + idx} required onChange={(e) => handleShareholderNameChange(this, 'SitePhone', idx, 'phone', e.target.value)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctPh"]' data-rule-phonenumber data-msg-notequaltogroup="Please enter a unique contact number" />
                                                        {idx > 0 ? <div className="btn_0_type" onClick={(e) => handleRemoveShareholder(this, e, idx, 'SitePhone')} >
                                                            <i className="icon icon-decrease-icon Add-2" ></i>
                                                        </div> : (this.state.SitePhone.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => handleAddShareholder(this, e, 'SitePhone', value)}>
                                                            <i className="icon icon-add-icons Add-1" ></i>
                                                        </div>
                                                        }
                                                    </div>
                                                </span>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="w-20-lg col-lg-2 col-sm-3">
                                        {
                                            this.state.SiteEmail.map((value, idx) => (
                                                <div className="mb-4" key={idx + 1}>
                                                    <label className="label_2_1_1">Email ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                                    <span className="required">
                                                        <div className="input_plus__">
                                                            <input type="email" className="input_f distinctEmail" placeholder="example@example.com" value={value.email} name={'org_email_' + idx} required onChange={(e) => handleShareholderNameChange(this, 'SiteEmail', idx, 'email', e.target.value)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctEmail"]' />
                                                            {idx > 0 ? <div className="btn_0_type" onClick={(e) => handleRemoveShareholder(this, e, idx, 'SiteEmail')} >
                                                                <i className="icon icon-decrease-icon Add-2" ></i>
                                                            </div> : (this.state.SiteEmail.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => handleAddShareholder(this, e, 'SiteEmail', value)}>
                                                                <i className="icon icon-add-icons Add-1" ></i>
                                                            </div>
                                                            }
                                                        </div>
                                                    </span>
                                                </div>
                                            ))}
                                    </div>
                                </div>

                                <div className="row P_15_T mb-5" >

                                    {this.state.completeAddress.map((value, idx) => (
                                        <span key={idx}>
                                            <div className="w-20-lg col-lg-2 col-sm-3">
                                                <label className="label_2_1_1">Site Address: </label>
                                                <span className="required">
                                                    <ReactGoogleAutocomplete className="input_f"
                                                        required={true}
                                                        data-msg-required="Add address"
                                                        name={'street' + idx}
                                                        onPlaceSelected={(place) => googleAddressFill(this, idx, 'completeAddress', 'street', place)}
                                                        types={['address']}
                                                        returntype={'array'}
                                                        value={value.street || ''}
                                                        onChange={(evt) => this.handleGoogleAddressChange(idx, 'completeAddress', 'street', evt.target.value)}
                                                        onKeyDown={(evt) => this.handleGoogleAddressChange(idx, 'completeAddress', 'street', evt.target.value)}
                                                        componentRestrictions={{ country: "au" }}
                                                    />
                                                </span>
                                            </div>

                                            <div className="w-10-lg col-lg-1 col-sm-2">
                                                <label className="label_2_1_1">State: </label>

                                                <Select clearable={false} name={"state_" + idx} className="default_validation" simpleValue={true} required={true}
                                                    value={value.state || ''}
                                                    onChange={(e) => this.handleGoogleAddressChange(idx, 'completeAddress', 'state', e)}
                                                    options={this.state.stateList} placeholder="Please Select" />
                                            </div>

                                            <div className="w-20-lg col-lg-2 col-sm-3  modify_select">
                                                <label className="label_2_1_1">Suburb:</label>
                                                <span className="required">

                                                    <Select.Async clearable={false} cache={false} className="default_validation" name={"city_" + idx}
                                                        value={{ label: value.city, value: value.city }}
                                                        disabled={(value.state) ? false : true}
                                                        loadOptions={(val) => getOptionsSuburb(val, value.state)}
                                                        placeholder="Please Select" required
                                                        onChange={(evt) => this.handleGoogleAddressChange(idx, 'completeAddress', 'city', evt)}
                                                    />

                                                </span>
                                            </div>

                                            <div className="w-10-lg col-lg-1 col-sm-2">
                                                <label className="label_2_1_1">Postcode: </label>
                                                <span className="required">

                                                    <input type="text"
                                                        value={value.postal || ''}
                                                        onChange={(e) => this.handleGoogleAddressChange(idx, 'completeAddress', 'postal', e.target.value)}
                                                        data-rule-required="true" className="text-center" name={"postal" + idx} placeholder="" data-rule-number="true" minLength="4" maxLength="4" data-rule-postcodecheck="true" />
                                                </span>
                                            </div>
                                        </span>
                                    ))}
                                    <div className="w-10-lg col-lg-1 col-sm-2">
                                        <label className="label_2_1_1">ABN: </label>
                                        <span className="required">
                                            <input type="text" value={this.state.abn} onChange={(e) => handleChangeChkboxInput(this, e)} className="text-left" name="abn" placeholder="" data-rule-required="true" data-rule-number minLength="11" maxLength="11" data-msg-number="Please enter 11 digit abn number" data-msg-minlength="Please enter 11 digit abn number" data-msg-maxlength="Please enter 11 digit abn number" />
                                        </span>
                                    </div>
                                </div>

                                <SiteContacts addressType="keyContactDetails" dataAry={this.state.keyContactDetails} bill_pay_by={this.state.bill_pay_by} title="Key Contact Details" handleShareholderNameChange={this.handleShareholderNameChange} handleRemoveShareholderDepth={this.handleRemoveShareholderDepth} handleShareholderNameChangeDepth={this.handleShareholderNameChangeDepth} handleAddShareholderDepth={this.handleAddShareholderDepth} handleChangeSelectDatepicker={this.handleChangeSelectDatepicker} />
                                
                                <SiteContacts addressType="billingContactDetails" dataAry={this.state.billingContactDetails} bill_pay_by={this.state.bill_pay_by} title="Billing Contact Details" handleShareholderNameChange={this.handleShareholderNameChange} handleRemoveShareholderDepth={this.handleRemoveShareholderDepth} handleShareholderNameChangeDepth={this.handleShareholderNameChangeDepth} handleAddShareholderDepth={this.handleAddShareholderDepth} handleChangeSelectDatepicker={this.handleChangeSelectDatepicker} />

                            </SimpleBar>
                            <div className="row d-flex flex-wrap justify-content-end ">
                                <div className="col-lg-12 pb-4"><div className="bt-1 w-100"></div></div>
                                {(this.props.hasOwnProperty('mode') && this.props.mode != 'edit') ?
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"><button disabled={this.state.loading} onClick={(e) => this.setState({ site_status: 2 }, () => { this.onSubmit(e) })} className="but_submit but_outline mb-3">Save Site as Draft</button></div> : ''}

                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12"><button disabled={this.state.loading} onClick={this.onSubmit} className="but_submit">Save Changes</button></div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

class SiteContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentWillReceiveProps(newProps) {
        this.setState(newProps);
        console.log('newProps',newProps);
    }

    render() {
            
        return (
            this.props.dataAry.map((value, idxx) => (
                <div key={idxx}>

                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="pAY_heading_01 bt-1"><div className="tXT_01"> {this.props.title}:</div></div>
                        </div>
                    </div>
                    <div className="row P_15_T d-flex flex-wrap after_before_remove" >

                        {(this.props.addressType == 'billingContactDetails') ?
                            <div className="w-20-lg col-lg-2 col-sm-3  mb-5 align-self-end">
                                <label className="label_2_1_1">Whom do you want to bill: </label>
                                <span className="required default_validation fixed_drop_down_issue_inputrenderer">
                                    <Select name='bill_pay_by' options={siteBillPayBy()} simpleValue={true} searchable={true} clearable={false} value={this.props.bill_pay_by} placeholder="Types" onChange={(e) => this.props.handleChangeSelectDatepicker(e, 'bill_pay_by')}
                                    inputRenderer={() => <input type="text" className="define_input" name={"bill_pay_by"} required={true} value={this.props.bill_pay_by || ''} readOnly />}
                                    />


                                </span>
                            </div>
                            : ''}
                    </div>

                    <div className="row P_15_T d-flex flex-wrap after_before_remove" >

                        <div className="w-20-lg col-lg-2 col-sm-3  mb-5 align-self-end">
                            <label className="label_2_1_1">First Name: </label>
                            <span className="required">
                                <input type="text" value={value.firstname} name={'firstname' + Math.random()} onChange={(e) => this.props.handleShareholderNameChange(this.props.addressType, idxx, 'firstname', e.target.value)} data-rule-required="true" className="text-left" placeholder="first" autoComplete="new-password" />
                            </span>
                        </div>

                        <div className="w-20-lg col-lg-2 col-sm-3  mb-5 align-self-end">
                            <label className="label_2_1_1">Last Name:</label>
                            <span className="required">
                                <input type="text" value={value.lastname} name={'lastname' + Math.random()} onChange={(e) => this.props.handleShareholderNameChange(this.props.addressType, idxx, 'lastname', e.target.value)} data-rule-required="true" className="text-left" placeholder="last" autoComplete="new-password" />
                            </span>
                        </div>

                        <div className="w-20-lg col-lg-2 col-sm-3  mb-5 align-self-end">
                            <label className="label_2_1_1">Position: </label>
                            <span className="required">
                                <input type="text" value={value.position} name={'position' + Math.random()} onChange={(e) => this.props.handleShareholderNameChange(this.props.addressType, idxx, 'position', e.target.value)} data-rule-required="true" className="text-left" placeholder="EG, CEO" autoComplete="new-password" />
                            </span>
                        </div>

                        <div className="w-20-lg col-lg-2 col-sm-3  mb-5 align-self-start">
                            <div>
                                <label className="label_2_1_1">Department:</label>
                                <span className="required">
                                    <input type="text" value={value.department} name={'department' + Math.random()} onChange={(e) => this.props.handleShareholderNameChange(this.props.addressType, idxx, 'department', e.target.value)} data-rule-required="true" className="text-left" placeholder="EG, Accounts" autoComplete="new-password" />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row P_15_T d-flex flex-wrap after_before_remove" >
                        <div className="w-20-lg col-lg-2 col-sm-3 mb-5 align-self-start" >
                            {value.sitePh.map((val, idx) => (
                                <div className="mb-3" key={idx + 1}>
                                    <label className="label_2_1_1">Phone ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                    <span className="required">
                                        <div className="input_plus__">
                                            <input type="text" className="input_f distinctPh" placeholder="Can Include Area Code" value={val.phone} name={'org_phone' + Math.random()} required onChange={(e) => this.props.handleShareholderNameChangeDepth(idxx, idx, 'phone', e.target.value, this.props.addressType)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctPh"]' data-rule-phonenumber data-msg-notequaltogroup="Please enter a unique contact number" />

                                            {idx > 0 ? <div className="btn_0_type" onClick={(e) => this.props.handleRemoveShareholderDepth(e, idx, this.props.addressType, 'sitePh', idxx, 'phone')} >
                                                <i className="icon icon-decrease-icon Add-2" ></i>
                                            </div> : (value.sitePh.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => this.props.handleAddShareholderDepth(e, this.props.addressType, value, 'sitePh', idxx, 'phone')}>
                                                <i className="icon icon-add-icons Add-1" ></i>
                                            </div>
                                            }
                                        </div>
                                    </span>
                                </div>
                            ))}
                        </div>

                        <div className="w-20-lg col-lg-2 col-sm-3 mb-5 align-self-start">
                            {
                                value.siteMail.map((val, idx) => (
                                    <div className="mb-3" key={idx + 1}>
                                        <label className="label_2_1_1">Email ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                        <span className="required">
                                            <div className="input_plus__">
                                                <input type="email" className="input_f distinctEmail" placeholder="example@example.com" value={val.email} name={'org_email' + Math.random()} required onChange={(e) => this.props.handleShareholderNameChangeDepth(idxx, idx, 'email', e.target.value, this.props.addressType)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctEmail"]' />
                                                {idx > 0 ? <div className="btn_0_type" onClick={(e) => this.props.handleRemoveShareholderDepth(e, idx, this.props.addressType, 'siteMail', idxx, 'email')} >
                                                    <i className="icon icon-decrease-icon Add-2" ></i>
                                                </div> : (value.siteMail.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => this.props.handleAddShareholderDepth(e, this.props.addressType, value, 'siteMail', idxx, 'email')}>
                                                    <i className="icon icon-add-icons Add-1" ></i>
                                                </div>
                                                }
                                            </div>
                                        </span>
                                    </div>
                                ))}
                        </div>

                        {(this.props.addressType == 'billingContactDetails')?
                        <React.Fragment >
                        
                            <div className={"w-20-lg col-lg-2 col-sm-3"}>
                                <label className="label_2_1_1">Payroll Tax:</label>
                                <span className="required">
                                    <div className="input_plus__ mb-1">
                                        <Select name="payroll_tax" required={true} className="default_validation" simpleValue={true} searchable={false} clearable={false} options={orgTax()} value={value.payroll_tax} onChange={(e) => this.props.handleShareholderNameChange(this.props.addressType, idxx,'payroll_tax',e)} placeholder="Exempt" 
                                            inputRenderer={() => <input type="text" className="define_input" name={"payroll_tax"} required={true} value={value.payroll_tax} readOnly />}
                                        />
                                    </div>
                                </span>
                            </div>

                            <div className={"w-20-lg col-lg-2 col-sm-3"}>
                                <label className="label_2_1_1">GST:</label>
                                <span className="required">
                                    <div className="input_plus__ mb-1">
                                        <Select name="gst" required={true} simpleValue={true} className="default_validation" searchable={false} clearable={false} options={orgGst()} value={value.gst} onChange={(e) => this.props.handleShareholderNameChange(this.props.addressType, idxx,'gst',e)} 
                                        inputRenderer={() => <input type="text" className="define_input" name={"gst"} required={true} value={value.gst} readOnly />}
                                        />
                                    </div>
                                </span>
                            </div>
                        </React.Fragment >:''}


                        {/*<div className="col-sm-3 mb-5  align-self-end">
                            {idxx > 0 ? <button title={OrganisationPageIconTitle.org_remove_site_Primary_details} onClick={(e) => this.handleRemoveHtml(e, idxx, this.props.addressType)} className="button_plus__ mt-4 mb-3">
                                <i className="icon icon-decrease-icon Add-2-2" ></i>
                            </button> : (this.props.dataAry.length == 3) ? '' : <button title={OrganisationPageIconTitle.org_add_site_Primary_details} className="button_plus__ mt-4 mb-3" onClick={(e) => this.handleAddHtml(e, this.props.addressType, value)}>
                                <i className="icon icon-add-icons Add-2-1" ></i>
                            </button>}
                        </div>*/}

                    </div>
                </div>
            ))
        );
    }
}

const mapStateToProps = state => ({
    OrganisationProfile: state.OrganisationReducer.orgProfile
})

const mapDispatchtoProps = (dispach) => {
    return {
        org_update_booking_comp_fun: (value) => dispach(setOrgBookingData(value)),
    }
}
export default connect(mapStateToProps, mapDispatchtoProps)(OrganisationUpdatePopUp)