import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_PATH } from 'config.js';

class ViewEnquiry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">

                        <div className=" py-4">
                            <span className="back_arrow ">
                                <Link to={ROUTER_PATH + "admin/helpdesk/enquiries"}>
                                    <span className="icon icon-back1-ie"></span>
                                </Link>
                            </span>
                        </div>


                        <div className="by-1 ">
                            <div className="row d-flex  py-4">
                                <div className="col-lg-12 col-md-2">
                                    <div className="h-h1 color">Enquiry No: 008000</div>
                                </div>
                            </div>
                        </div>

                        <div className={'Bg_W_moule mt-5'}>

                            <div className={'row d-flex justify-content-center'}>
                                <div className={'col-lg-8'}>

                                    <div className={'enq_viewList'}>

                                        <div className={'enquiryView'}>
                                            <div className={'d-flex mb-5 align-items-center'}>
                                                <span className="short_buttons_01 btn_color_avaiable pointer-events-none mr-4">Active</span>
                                                <span className={'flex-1 pr-3'}>Enquiry Raised: 29/11/2019</span>
                                                <span><strong>Priority: High</strong></span>
                                            </div>
                                            <h3 className={'color mb-5'}>RE:[Title]</h3>
                                            <h5 className={' mb-5'}><strong>Category: Finance</strong></h5>
                                            <div>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                 when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                 It has survived not only five centuries, but also the leap into electronic typesetting,
                                                 remaining essentially unchanged. It was popularised in the 1960s with the release of
                                                 Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                                                 software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </div>
                                            <div className={'d-flex justify-content-end mt-5'}>
                                                <button className={'btn cmn-btn2 mr-3 enq_btn'}>Acknowledge as Result</button>
                                                <button className={'btn cmn-btn1 enq_btn'}>Reply</button>
                                            </div>
                                        </div>

                                        <div className={'enquiryView'}>
                                            <div></div>
                                            <h3 className={'color mb-5'}>RE:[Title]</h3>
                                            <h5 className={' mb-5'}><strong>Category: Finance</strong></h5>
                                            <div>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                 when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                 It has survived not only five centuries, but also the leap into electronic typesetting,
                                                 remaining essentially unchanged. It was popularised in the 1960s with the release of
                                                 Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                                                 software like Aldus PageMaker including versions of Lorem Ipsum.<br/><br/>
                                                 Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                 when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                 It has survived not only five centuries, but also the leap into electronic typesetting,
                                                 remaining essentially unchanged. It was popularised in the 1960s with the release of
                                                 Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                                                 software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </div>
                                           
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>



                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default ViewEnquiry;