import React from 'react';
import { connect } from 'react-redux';
import { ROUTER_PATH } from 'config.js';
import { Link } from 'react-router-dom';
import Select from 'react-select-plus';


class CreateEnquiry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {


        const opts = [
            { value: '1', label: 'opt 1' }
        ]

        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-lg-12">

                        <div className=" py-4">
                            <span className="back_arrow ">
                                <Link to={ROUTER_PATH + "admin/helpdesk/enquiries"}><span className="icon icon-back1-ie"></span></Link>
                            </span>
                        </div>


                        <div className="by-1 ">
                            <div className="row d-flex  py-4">
                                <div className="col-lg-12 col-md-2">
                                    <div className="h-h1 color">Send New Enquiry</div>
                                </div>

                            </div>
                        </div>


                        <div className={'Bg_F_moule mt-5'}>
                            <div className={'row d-flex justify-content-center'}>
                                <div className={'col-lg-10'}>
                                    <form>

                                        <div className={'row d-flex'}>

                                            <div className="col-lg-6 col-sm-6">
                                                <div className={'form-group'}>
                                                    <label className="label_2_1_1">First Name</label>
                                                    <input type="text" placeholder={'first name'} />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6">
                                                <div className={'form-group'}>
                                                    <label className="label_2_1_1">Surname</label>
                                                    <input type="text" placeholder={'Surname'} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'row d-flex'}>

                                            <div className="col-lg-6 col-sm-6">
                                                <div className={'form-group'}>
                                                    <label className="label_2_1_1">Category</label>
                                                    <div className={'sLT_gray left left-aRRow'}>
                                                        <Select
                                                            name={'category'}
                                                            required={true} simpleValue={true}
                                                            className="custom_select default_validation"
                                                            options={opts}
                                                            clearable={false}
                                                            searchable={false}
                                                            placeholder='Select'
                                                        // inputRenderer={(props) => <input type="text" type="text" name="addtitonal_paypoint_ratetype" {...props} readOnly />}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6">
                                                <div className={'form-group'}>
                                                    <label className="label_2_1_1">Priority</label>
                                                    <div className={'sLT_gray left left-aRRow'}>
                                                        <Select
                                                            name={'priority'}
                                                            required={true} simpleValue={true}
                                                            className="custom_select default_validation"
                                                            options={opts}
                                                            clearable={false}
                                                            searchable={false}
                                                            placeholder='Select'
                                                        // inputRenderer={(props) => <input type="text" type="text" name="addtitonal_paypoint_ratetype" {...props} readOnly />}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className={'row d-flex'}>
                                            <div className="col-lg-12 col-sm-12">
                                                <div className={'form-group'}>
                                                    <label className="label_2_1_1">Enquiry Title</label>
                                                    <input type="text" placeholder={'Enquiry Title'} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'row d-flex mt-3 mb-3'}>
                                            <div className="col-lg-12 col-sm-12">
                                                <Link to={'#'} className={'color'}><strong>[View Knowledge Base article that contain keywords from this enquiry title]</strong></Link>
                                            </div>
                                        </div>

                                        <div className="row mt-5 ">

                                            <div className="col-lg-12">
                                                <div className={'form-group'}>
                                                    <label className="label_2_1_1">Enquiry Field</label>
                                                    <textarea className="w-100 textAra_big1__" name="comments" ></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">

                                                <div className={'form-group pb-5'}>
                                                    <div className={'row d-flex justify-content-end'}>
                                                        <div className={'col-lg-2 col-md-4'}>
                                                            <span className="v-c-btn1 n2">
                                                                <span>Add Attachment</span>
                                                                <i className="icon icon-attach-im "></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={'form-group mt-5'}>
                                                    <div className={' d-flex justify-content-end'}>
                                                    <button className={'btn cmn-btn1 big_btn__'}>Send Enquiry</button>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default connect(null, null)(CreateEnquiry);