const initialState = {
  case_details: {
   id:''
  },
  activePage:{pageTitle:'',pageType:''},
  fmsFeedCategory : [],
  fmsInitiatorCategory : [],
  fmsAgainstCategory : [],
  fmsDepartmentDetails : [],
  feedbackDetails : {}
}


const FmsCaseDetailsData = (state = initialState, action) => {
  switch (action.type) {
    case 'set_fms_case_details_data':
    return { ...state, case_details: action.detailsData };

    case 'set_active_page_fms':
    return {...state, activePage: action.value};

    case 'set_fms_feed_details_data':
    return {...state, feedbackDetails: action.data};

    default:
            return state
        }
    }
export default FmsCaseDetailsData