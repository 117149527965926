const initaileState = {
    participantProfile: {
        firstname: '',
        id: '',
        fullName: ''
    },
    ActiveClassProfilePage: 'about',
    activePage:{pageTitle:'',pageType:''},
    relationList: [],
    current_plans:[],
}

const ParticipantProfile = (state = initaileState, action) => {

    switch (action.type) {
        case 'set_participant_profile_data':
            return {...state, participantProfile: action.profileData};

        case 'set_active_class_profile_page':
            return {...state, ActiveClassProfilePage: action.value};

        case 'update_participant_profile_data':
            var obj1  = state.participantProfile;
            var obj2 = action.profileData;

            var mergedObj = { ...obj1, ...obj2 };
            return { ...state, participantProfile: mergedObj };
        case 'set_active_page_participaint':
        return {...state, activePage: action.value};
        
         case 'set_relation_list_option':
        return {...state, 
            relationList: action.options};

        case 'set_participant_current_plan':
            return {...state, current_plans: action.data};    
        default:
            return state
    }
}

export default ParticipantProfile
    