import { postData} from '../../../../service/common.js';
import {clearImailNotification} from './../../notification/actions/NotificationAction';

// action for set external mail data listing
export function setMessageListing(mail_data) {
    return {
        type: 'set_external_imail_message_listing',
        mail_data
    }
}

// action for remove mail from listing
export const setMessageAllContent = (mail_data) => ({
        type: 'set_external_imail_all_content',
        mail_data
})

// action for remove mail from listing
export const removeMailFromListing = (messageId) => ({
        type: 'remove_external_mail_from_listing',
        messageId
    })   

// action update mail content data value
export const updateMailContentValue = (update_data) => ({
        type: 'update_external_mail_content_value',
        update_data
    })

// action update listing value
export const updateMailListingValue = (update_data, messageId) => ({
        type: 'update_external_mail_listing_value',
        messageId: messageId,
        update_data
    })

// action for set reply mail
export const replyMail = (status, messageId) => ({
        type: 'reply_mail_of_external_mail',
        status: status,
        messageId
})    

// action for set status create new mail
export const composeNewMailStatus = (status) => ({
        type: 'compose_new_external_mail',
        status
})  
	
// action for set mail type
export const setExternalMailType = (mail_type) => ({
        type: 'set_external_mail_type',
        mail_type
    })


// action for set fetching status true or false
export const setFetchingStatusRequest = (value) => ({
        type: 'set_external_mail_fetching_status',
        value
    }) 

// action for set fetching status true or false
export const setFetingMailDetailsStatus = (value) => ({
        type: 'set_external_mail_details_fetching_status',
        value
}) 

// ascronus middleware for fetch data 
function fetchExternalMail(request) {
    return dispatch => {
        dispatch(setFetchingStatusRequest(true));
        return postData('imail/External_imail/get_external_messages', request).then((result) => {
            if (result.status) {
                dispatch(setMessageListing(result.data))
            }
            dispatch(setFetchingStatusRequest(false));
        });
    }
}

// middle ware for set data
export function getExternalMailListing(request) {
    return (dispatch, getState) => {
        return dispatch(fetchExternalMail(request))
    }
}


// ascronus middleware for fetch data of mail content
function fetchMailContent(request) {
    return dispatch => {
        dispatch(setFetingMailDetailsStatus(true))
        return postData('imail/External_imail/get_single_chat', request).then((result) => {
            if (result.status) {
                 dispatch(setMessageAllContent(result.data))
                 
                 if(result.data.content.length > 0){
                    result.data.content.map((val, index) => {
                        if(val.is_notify == 0 && val.is_read == 0){
                            dispatch(clearImailNotification({contentId: val.id, type : 'external'}))
                        }
                    })
                }
            }
            dispatch(setFetingMailDetailsStatus(false))
      });
    }
}

// middle ware for set data of mail content
export function getExternalMailContent(request) {
    return (dispatch, getState) => {
        return dispatch(fetchMailContent(request))
    }
}
