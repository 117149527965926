export const itemJson = [
    { 
        name: 'Documents', 
        id:'document', 
        submenus: [], 
        path: '/admin/item/document' 
    },
    { 
        name: 'Role', 
        id:'role', 
        submenus: [], 
        path: '/admin/item/role' 
    }
];