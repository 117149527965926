import React, { Component } from 'react';
import { ROUTER_PATH, PAGINATION_SHOW } from '../../../config.js';
import OrganisationAddSitePopUp from './OrganisationAddSitePopUp';
import { checkItsNotLoggedIn, postData, handleChangeChkboxInput } from '../../../service/common.js';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import Pagination from "../../../service/Pagination.js";
import { setActiveSelectPage } from './actions/OrganisationAction.js';
import { OrganisationPageIconTitle } from 'menujson/pagetitle_json';
import { CounterShowOnBox } from 'service/CounterShowOnBox.js';
import { connect } from 'react-redux'

const requestData = (pageSize, page, sorted, filtered) => {
    return new Promise((resolve, reject) => {
        // request json
        var Request = JSON.stringify({ pageSize: pageSize, page: page, sorted: sorted, filtered: filtered });
        postData('organisation/OrgDashboard/get_org', Request).then((result) => {
            let filteredData = result.data;
            const res = {
                rows: filteredData,
                pages: (result.count),
                total_duration: result.total_duration
            };
            resolve(res);
        });
    });
};

class OrganisationListDashboard extends Component {
    constructor(props) {
        checkItsNotLoggedIn(ROUTER_PATH);
        super(props);
        this.state = {
            caseListing: [],
            search_value: '',
            include_inactive: false,
            include_incomplete: false,
            filtered: [],
            newOrgThisMonth: '00',
            loading: false
        }
    }

    componentDidMount() {
        this.props.setActiveSelectPage('org_dashboard_listing');
        this.getOrgStats();
    }

    getOrgStats = () => {
        postData('organisation/OrgDashboard/get_org_stats').then((result) => {
            if (result.status) {
                this.setState({ newOrgThisMonth: result.data.org_this_month }, () => { })
            }
        });
    }

    fetchData = (state, instance) => {
        this.setState({ loading: true });
        requestData(
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            this.setState({
                caseListing: res.rows,
                pages: res.pages,
                loading: false,
                total_duration: res.total_duration
            });
        })
    }

    searchData = (e) => {
        e.preventDefault();
        var requestData = {
            srch_box: this.state.search_value,
            include_inactive: this.state.include_inactive,
            include_incomplete: this.state.include_incomplete,
            filter_type: this.state.hasOwnProperty('filteredData') ? this.state.filteredData.filter_type : ''
        };
        this.setState({ filtered: requestData });
    }

    closeModal = () => {
        this.setState({ modal_show: false });
    }

    render() {
        var filterType = [
            { value: 'sub_org', label: 'Sub-Org' },
            { value: 'site', label: 'Site' }
        ];

        var org_detail = '';
        const columns = [
            {
                Header: 'HCM-ID', accessor: 'id', filterable: false, className: '_align_c__', Cell: props => <span>
                    <Link className="inherit-color" to={"/admin/organisation/overview/" + props.original.id}>{props.original.id}</Link>
                </span>, maxWidth: 150
            },
            { Header: 'Name', accessor: 'name', filterable: false, sortable: false, className: '_align_c__' },
            { Header: 'Type', accessor: 'a', filterable: false, sortable: false, className: '_align_c__' },
            { Header: 'Address ', accessor: 'address', filterable: false, className: '_align_c__' },
            { Header: 'Phone ', accessor: 'phone', sortable: false, className: '_align_c__' },
            {
                Header: 'Sub-Orgs/Sites ', accessor: 'description', sortable: false, className: '_align_c__', Cell: props => <span>
                    {org_detail = ' '}
                    {org_detail = <span>{props.original.sub_org_count}<br /></span>}
                    {org_detail = props.original.site_count}
                </span>
            },
            {
                Header: 'Action', accessor: 'id', filterable: false, className: '_align_c__',
                Cell: props => <span>
                    <Link className="short_buttons_01 mr-3" title={OrganisationPageIconTitle.org_details_icon} to={"/admin/organisation/overview/" + props.original.id} >View</Link>
                </span>, maxWidth: 115
            }
        ]

        const include_incomplete = 0;
        return (
            <div>

                <div className="row _Common_He_a">
                    {/* 
                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <Link to={ROUTER_PATH + 'admin/organisation/createOrganisation'} className="Plus_button"><i className="icon icon-add-icons create_add_but"></i><span>Create New Org</span></Link>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <a className="Plus_button" onClick={() => this.setState({ modal_show: true })}><i className="icon icon-add-icons create_add_but"></i><span>Create House/Site</span></a>

                        {(this.state.modal_show) ?
                            <OrganisationAddSitePopUp
                                modal_show={this.state.modal_show}
                                closeModal={this.closeModal}
                                callType="1"
                                mode="add"
                            />
                            : ''}
                    </div> */}
                </div>



                <div className="row">

                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">

                        <div className="status_box1 d-flex flex-wrap">
                            <div className="d-flex flex-wrap w-100">
                                <h4 className="hdng w-100">Shift Filled This Month</h4>
                                <div className="w-100">
                                    <CounterShowOnBox counterTitle={'25'} classNameAdd="" mode="other" placeHoldeShow={false}/>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="status_box1 d-flex flex-wrap">
                            <div className="d-flex flex-wrap w-100">
                                <h4 className="hdng w-100">New Orgs in Our Network This Month</h4>
                                <div className="w-100">
                                    <CounterShowOnBox counterTitle={this.state.newOrgThisMonth} classNameAdd="" mode="other" placeHoldeShow={false}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <div className="status_box1 d-flex flex-wrap">
                            <div className="d-flex flex-wrap w-100">
                                <h4 className="hdng w-100">Orgs With shifts This Month</h4>
                                <div className="w-100">
                                    <CounterShowOnBox counterTitle={'00'} classNameAdd="" mode="other" placeHoldeShow={false}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <div className="bor_T"></div>
                    </div>
                </div>

                <div className="row _Common_Search_a">
                    <form className="col-lg-6 col-md-6 col-sm-12" id="srch_feedback" autoComplete="off" onSubmit={this.searchData} method="post">
                        <div className="search_bar ad_search_btn right srchInp_sm actionSrch_st">
                            <input type="text" className="srch-inp" placeholder="Search" name="search_value" value={this.state.search_value} onChange={(e) => handleChangeChkboxInput(this, e)} />
                            <button type="submit"><i className="icon icon-search2-ie"></i></button>
                        </div>
                    </form>

                    <div className="col-lg-3 col-md-3 col-xs-12">
                        <div className="box font_big">
                            <Select name="filter_type" required={true} simpleValue={true} searchable={false} Clearable={false} placeholder="Filter by Type"
                                options={filterType}
                                onChange={(e) => this.setState({
                                    filtered:
                                    {
                                        include_inactive: this.state.include_inactive,
                                        include_incomplete: this.state.include_incomplete,
                                        filter_type: e,
                                        srch_box: this.state.search_value,
                                    }
                                })}
                                value={this.state.filtered.filter_type}
                            />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="row ">
                            <span className="col-sm-6 include_box">
                                <input type="checkbox" className="checkbox_big" id="Include" name="include_inactive" checked={this.state['include_inactive']} value={this.state.include_inactive} onChange={(e) => this.setState({ include_inactive: e.target.checked, filtered: { include_inactive: e.target.checked, include_incomplete: this.state.include_incomplete, srch_box: this.state.search_value, filter_type: this.state.hasOwnProperty('filteredData') ? this.state.filteredData.filter_type : '' } })} />
                                <label htmlFor="Include"><span></span><small className="pl-1">Include Inactive</small></label>
                            </span>

                            <span className="col-sm-6 include_box">
                                <input type="checkbox" className="checkbox_big" id="incomplete" name="include_incomplete" checked={this.state['include_incomplete']} value={this.state.include_incomplete} onChange={(e) => this.setState({ include_incomplete: e.target.checked, filtered: { include_incomplete: e.target.checked, include_inactive: this.state.include_inactive, srch_box: this.state.search_value, filter_type: this.state.hasOwnProperty('filteredData') ? this.state.filteredData.filter_type : '' } })} />
                                <label htmlFor="incomplete"><span></span><small className="pl-1">Include Incomplete</small></label>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-sm-12 P_25_b">
                        <div className="bor_T"></div>
                    </div>
                </div>

                <div className="row">
                    <article className="col-lg-12">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="listing_table PL_site th_txt_center__ odd_even_tBL  line_space_tBL H-Set_tBL">
                                    <ReactTable
                                        PaginationComponent={Pagination}
                                        columns={columns}
                                        manual
                                        data={this.state.caseListing}
                                        pages={this.state.pages}
                                        loading={this.state.loading}
                                        onFetchData={this.fetchData}
                                        filtered={this.state.filtered}
                                        defaultFiltered={{ is_active: false }}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        noDataText="No Record Found"
                                        minRows={2}
                                        previousText={<span className="icon icon-arrow-left privious"></span>}
                                        nextText={<span className="icon icon-arrow-right next"></span>}
                                        getTrProps={(state, rowInfo, column, instance) => ({
                                            onClick: e => this.setState({ organisation_id: rowInfo.original.id }, () => { })
                                        })}
                                        showPagination={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        );
    }
}
//
const mapStateToProps = state => ({
    //ActiveClass : state.OrganisationReducer.ActiveClassProfilePage,
    //    OrganisationProfile : state.OrganisationReducer.orgProfile
})

const mapDispatchtoProps = (dispach) => {
    return {
        setActiveSelectPage: (value) => dispach(setActiveSelectPage(value)),
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(OrganisationListDashboard)

