import React, { Component } from 'react';
import { ROUTER_PATH } from '../../../config.js';
import { checkItsNotLoggedIn, postData } from '../../../service/common.js';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import 'react-table/react-table.css'
import 'react-select-plus/dist/react-select-plus.css';

import { setActiveSelectPage } from './actions/OrganisationAction.js';
import { OrganisationPageIconTitle } from 'menujson/pagetitle_json';
import { connect } from 'react-redux'
import OrganisationTextDashboard from './OrganisationTextDashboard.js';
import OrganisationListDashboard from './OrganisationListDashboard.js';
import OrganisationAddSitePopUp from './OrganisationAddSitePopUp';

import ReactPlaceholder from 'react-placeholder';
import { orgDashboardCreateBox, orgDashboardListBox } from '../../../service/CustomContentLoader.js';

class OrganisationDashboard extends Component {
    constructor(props) {
        checkItsNotLoggedIn(ROUTER_PATH);
        super(props);
        this.state = {
            orgCount: 0,
            loading: false
        }
    }

    componentDidMount() {
        this.getOrgCount();
    }

    getOrgCount = () => {
        postData('organisation/OrgDashboard/get_org_count').then((result) => {
            if (result.status) {
                this.setState({ orgCount: result.count, loading: true }, () => { })
            }
        });
    }
    closeModal = () => {
        this.setState({ modal_show: false });
    }

    render() {

        return (
            <div>

                <div className="row  _Common_back_a">
                    <div className="col-lg-12 col-sm-12">
                        <Link className="d-inline-flex" to={ROUTER_PATH + 'admin/dashboard'}><span className="icon icon-back-arrow back_arrow"></span></Link></div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <div className="bor_T"></div>
                    </div>

                </div>
                <div className="row _Common_He_a">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h1 className="color">Organisations</h1>
                    </div>

                    <ReactPlaceholder defaultValue={''} showLoadingAnimation={true} customPlaceholder={orgDashboardCreateBox()} ready={this.state.loading}>

                        {(this.state.orgCount > 0) ?
                            <React.Fragment>
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                    <Link to={ROUTER_PATH + 'admin/organisation/createOrganisation'} className="Plus_button"><span>New org/Sub-org</span><i className="icon icon-add-icons create_add_but"></i></Link>
                                </div>

                                <div className="col-lg-3 col-md-3 col-sm-6">
                                    <a className="Plus_button" onClick={() => this.setState({ modal_show: true, pop_title: 'Add new site' })}><span>New Site</span><i className="icon icon-add-icons create_add_but"></i></a>

                                    {(this.state.modal_show) ?
                                        <OrganisationAddSitePopUp
                                            modal_show={this.state.modal_show}
                                            closeModal={this.closeModal}
                                            callType="1"
                                            mode="add"
                                            title={this.state.pop_title}
                                        />
                                        : ''}
                                </div>
                            </React.Fragment>
                            : ''}
                    </ReactPlaceholder>

                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <div className="bor_T"></div>
                    </div>
                </div>


                <div className="row">
                    <article className="col-lg-12">
                        <div className="row">
                            <div className="col-sm-12 listing_table PL_site">
                                <ReactPlaceholder defaultValue={''} showLoadingAnimation={true} customPlaceholder={orgDashboardListBox()} ready={this.state.loading}>
                                    {(this.state.orgCount > 0) ?
                                        <OrganisationListDashboard /> : <OrganisationTextDashboard />
                                    }
                                </ReactPlaceholder>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        );
    }
}

//
const mapStateToProps = state => ({
    //ActiveClass : state.OrganisationReducer.ActiveClassProfilePage,
    //    OrganisationProfile : state.OrganisationReducer.orgProfile
})

const mapDispatchtoProps = (dispach) => {
    return {
        //setActiveSelectPage: (value) => dispach(setActiveSelectPage(value)),
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(OrganisationDashboard)

