const initaileState = {
    house_profile_details: {

    },
    house_email: [],
    house_phone: [],
    support_contact: {
        contact_name: "",
        contact_email: [],
        contact_phone: []
    },
    key_contact: {
        contact_name: "",
        contact_email: [],
        contact_phone: []
    },
    house_docs: [],

    is_loading: false,
}

const HouseReducer = (state = initaileState, action) => {
    switch (action.type) {
        case 'set_house_profile_details':
            return {...state, house_profile_details: action.profile_details};

        case 'set_house_about_details':
            return {...state, ...action.about_details};

        case 'set_loading_status':
            return {...state, is_loading: action.status};
            
        case 'set_house_and_participant_doc':
            return {...state, house_docs: JSON.parse(JSON.stringify(action.docs))};
            
        

        default:
            return state
}
}

export default HouseReducer;