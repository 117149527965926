import React from 'react'

import { checkItsNotLoggedIn } from '../../service/common.js'
import SettingsApiDocuSign from './settings/SettingsApiDocuSign.jsx'
import SettingsApiSeek from './settings/SettingsApiSeek.jsx'



class SettingsApi extends React.Component {

    constructor(props) {
        super(props)

        checkItsNotLoggedIn()
    }

    render() {
        return (
            <section className="gradient_color">
                <div className="container-fluid fixed_size">

                    <div className="col-md-10 col-md-offset-1">

                        <div className="row d-flex w-100 justify-content-center mt-5">
                            <div className="col-md-9 mt-5">
                                <div className="H_B_1">Update API settings</div>
                            </div>
                        </div>
                        <SettingsApiDocuSign />
                        <SettingsApiSeek />
                    </div>
                </div>
            </section>
        )
    }
}

export default SettingsApi
