import React from 'react';

import { DataTable,IconSettings, Input, Button,Card } from '@salesforce/design-system-react';
import { postData,toastMessageShow } from 'service/common.js';

class ImportCMSContent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lastversion: '',
		}
	}
	handleclick = () => {
		let Request = {url: this.state.url}
		if(!Request) {
			toastMessageShow("Please Enter URL!", "e");
		}
		postData('storeCMScontent', Request).then((result) => {

			if(result.status) {
				toastMessageShow("Content Import Success", "s");
				document.getElementById('url').value = '';
			}
			else {

				toastMessageShow("Something went wrong", "e");
			}

		});
	}
render() {
		return (
			<IconSettings iconPath="/assets/icons">
					<div className="slds-grid slds-grid_vertical" style={{padding:'12px'}}>

						<Card
							id="ImportCard"
							heading="Import Content"
							style={{padding:'12px'}}
						>
							<Input id="url" label="S3 URL" placeholder="Enter url" required
							onChange={event => this.setState({ url: event.target.value})}
							/>

							<Button label="Import" variant="outline-brand"
							onClick ={this.handleclick}
							style={{marginTop:'12px'}}
							/>
						</Card>
					</div>

			</IconSettings>
		);
	}

}

export default ImportCMSContent;