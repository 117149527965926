import React from 'react'
import classNames from 'classnames'

/**
 * @typedef {typeof iconClassKeys} IconClassKeys
 */

const iconClassKeys = {
    'achive': true,
    'add-big': true,
    'add-icons': true,
    'arrow-up': true,
    'arrow-down': true,
    'arrow-left': true,
    'arrow-right': true,
    'attach': true,
    'back-arrow': true,
    'boy': true,
    'calander': true,
    'check': true,
    'circle': true,
    'circule': true,
    'color-piker': true,
    'cross-icons': true,
    'decrease-icon': true,
    'done-arrow': true,
    'download': true,
    'dublicate-icon': true,
    'email-pending': true,
    'error-icons': true,
    'event-location': true,
    'file-icons': true,
    'folder': true,
    'girls': true,
    'graph': true,
    'info': true,
    'input-type-check': true,
    'invoice': true,
    'like': true,
    'lock-icons': true,
    'log-out': true,
    'message': true,
    'minus': true,
    'mobile': true,
    'notes': true,
    'notification-icons': true,
    'ocs-logo': true,
    'pending-icons': true,
    'phone-icons': true,
    'plus': true,
    'reload': true,
    'un-attach-icons': true,
    'un-add-icon': true,
    'star-icons': true,
    'star': true,
    'small-search': true,
    'smail-big': true,
    'share': true,
    'setting': true,
    'search': true,
    'share-icon': true,
    'unlike': true,
    'uncheck': true,
    'user': true,
    'update': true,
    'view': true,
    'caller-icons': true,
    'user-icon': true,
    'social-vimeo': true,
    'facebook': true,
    'facebook-alt': true,
    'whatsapp': true,
    'linkedin': true,
    'linkedin-alt': true,
    'share-1': true,
    'social-instagram': true,
    'twitter': true,
    'twitter-alt': true,
    'letter-mail-1': true,
    'at-email': true,
    'address-book': true,
    'vimeo': true,
    'yahoo': true,
    'skype': true,
    'arrow-1-down': true,
    'arrow-1-left': true,
    'arrow-1-right': true,
    'arrow-1-up': true,
    'alert': true,
    'align-center': true,
    'align-justify': true,
    'align-left': true,
    'align-right': true,
    'archive': true,
    'camera': true,
    'volume-strike': true,
    'trash': true,
    'social-googleplus': true,
    'pricetags': true,
    'android-create': true,
    'speaker-louder': true,
    'speaker-off': true,
    'speaker-silent': true,
    'bell-slash': true,
    'bell-slash-o': true,
    'bell-o': true,
    'book': true,
    'hand-o-down': true,
    'hand-o-left': true,
    'hand-o-right': true,
    'hand-o-up': true,
    'youtube': true,
    'calendar': true,
    'blank-day': true,
    'blank-day-1': true,
    'day-1': true,
    'day-2': true,
    'day-3': true,
    'day-4': true,
    'day-5': true,
    'day-6': true,
    'day-7': true,
    'day-8': true,
    'day-9': true,
    'day-10': true,
    'day-13': true,
    'day-14': true,
    'day-15': true,
    'day-16': true,
    'day-17': true,
    'day-18': true,
    'day-19': true,
    'day-20': true,
    'day-21': true,
    'day-22': true,
    'day-23': true,
    'day-26': true,
    'day-27': true,
    'day-28': true,
    'day-29': true,
    'day-30': true,
    'day-31': true,
    'day-11': true,
    'day-12': true,
    'day-24': true,
    'day-25': true,
    'views': true,
    'add-icon-input-in': true,
    'long-arrow-down': true,
    'long-arrow-left': true,
    'long-arrow-right': true,
    'long-arrow-up': true,
    'bag': true,
    'hand-paper-o': true,
    'cross-icons-1': true,
    'crosshairs': true,
    'trash-o': true,
    'bars': true,
    'times': true,
    'close': true,
    'menu': true,
    'arrow-l': true,
    'arrow-r': true,
    'plane': true,
    'archive-im': true,
    'attach-im': true,
    'envelope-im': true,
    'favorite-im': true,
    'file-im': true,
    'flag-im': true,
    'fully-back-im': true,
    'next-im': true,
    'single-back-im': true,
    'warning-im': true,
    'block-im': true,
    'accept-approve3-ie': true,
    'accept-approve2-ie': true,
    'accept-approve1-ie': true,
    'add4-ie': true,
    'add3-ie': true,
    'add2-ie': true,
    'add1-ie': true,
    'approved3-ie': true,
    'approved2-ie': true,
    'approved1-ie': true,
    'archive5-ie': true,
    'archive4-ie': true,
    'archive3-ie': true,
    'archive2-ie': true,
    'archive1-ie': true,
    'arrow8-ie': true,
    'arrow7-ie': true,
    'arrow6-ie': true,
    'arrow5-ie': true,
    'attatchments3-ie': true,
    'attatchments2-ie': true,
    'attatchments1-ie': true,
    'availmem4-ie': true,
    'availmem3-ie': true,
    'availmem1-ie': true,
    'back3-ie': true,
    'availmem2-ie': true,
    'back2-ie': true,
    'back1-ie': true,
    'block1-ie': true,
    'call3-ie': true,
    'call2-ie': true,
    'call1-ie': true,
    'checkbox2-ie': true,
    'checkbox1-ie': true,
    'circle1-ie': true,
    'close3-ie': true,
    'close2-ie': true,
    'close1-ie': true,
    'document6-ie': true,
    'document4-ie': true,
    'document5-ie': true,
    'document3-ie': true,
    'document2-ie': true,
    'document1-ie': true,
    'download3-ie': true,
    'download2-ie': true,
    'download1-ie': true,
    'edit6-ie': true,
    'edit5-ie': true,
    'edit4-ie': true,
    'edit3-ie': true,
    'edit2-ie': true,
    'edit1-ie': true,
    'envelope1-ie': true,
    'export1-ie': true,
    'fillshift3-ie': true,
    'fillshift2-ie': true,
    'fillshift1-ie': true,
    'flag2-ie': true,
    'flag1-ie': true,
    'history3-ie': true,
    'history2-ie': true,
    'history1-ie': true,
    'imail4-ie': true,
    'imail3-ie': true,
    'imail2-ie': true,
    'imail1-ie': true,
    'info3-ie': true,
    'info2-ie': true,
    'info1-ie': true,
    'like2-ie': true,
    'like1-ie': true,
    'listen3-ie': true,
    'listen2-ie': true,
    'listen1-ie': true,
    'location1-ie': true,
    'lock1-ie': true,
    'logout6-ie': true,
    'logout5-ie': true,
    'logout4-ie': true,
    'logout3-ie': true,
    'logout2-ie': true,
    'logout1-ie': true,
    'next3-ie': true,
    'next2-ie': true,
    'next1-ie': true,
    'notes3-ie': true,
    'notes2-ie': true,
    'notes1-ie': true,
    'notification4-ie': true,
    'notification3-ie': true,
    'notification2-ie': true,
    'notification1-ie': true,
    'pussapp3-ie': true,
    'pussapp2-ie': true,
    'pussapp1-ie': true,
    'q-mark2-ie': true,
    'q-mark1-ie': true,
    'recode1-ie': true,
    'remove4-ie': true,
    'remove3-ie': true,
    'remove2-ie': true,
    'remove1-ie': true,
    'search3-ie': true,
    'search2-ie': true,
    'search1-ie': true,
    'setting1-ie': true,
    'star2-ie': true,
    'update1-ie': true,
    'userm2-ie': true,
    'userm1-ie': true,
    'userf2-ie': true,
    'userf1-ie': true,
    'view3-ie': true,
    'view2-ie': true,
    'view1-ie': true,
    'warning2-ie': true,
    'warning1-ie': true,
    'pin': true,
    'active': true,
    'ins-icon': true,
    'bredcrumb-arrow': true,
    'if-ic-file-upload-48px-352345': true,
    'bug': true,
    'ios-plus-empty': true,
    'ios-minus-empty': true,
    'incative': true,
    'house': true,
    'roster-2': true,
    'roster-1': true,
    'flag3': true,
    'pdf': true,
}

/**
 * Component for displaying icons based on 'icons.me'
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & { icon: keyof IconClassKeys }} props 
 */
export default function (props) {
    const classes = classNames('icon', `icon-${props.icon}`, props.className)

    const iProps = {
        ...props,
        icon: undefined
    }


    return <i {...iProps} className={classes} />
}
