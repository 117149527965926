import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { ROUTER_PATH, PAGINATION_SHOW } from '../../../config.js';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { postData, checkItsNotLoggedIn } from '../../../service/common.js';
import Pagination from "../../../service/Pagination.js";
import { TotalShowOnTable } from '../../../service/TotalShowOnTable';
import { MemberPageIconTitle } from 'menujson/pagetitle_json';

// globale varibale to store data
const requestData = (pageSize, page, sorted, filtered) => {
    return new Promise((resolve, reject) => {
        // request json
        var Request = JSON.stringify({ pageSize: pageSize, page: page, sorted: sorted, filtered: filtered });
        postData('member/MemberDashboard/load_member_list', { request: Request }).then((result) => {
            if (result.status) {
                let filteredData = result.data;
                const res = {
                    rows: filteredData,
                    pages: (result.count),
                    total_count: result.total_count
                };

                resolve(res);
            } else {
                //this.setState({avail:result.data});
            }
        });

    });
};



/**/

class MembersList extends React.Component {
    constructor(props) {
        super(props);
        checkItsNotLoggedIn(ROUTER_PATH);
        this.state = { css_file: 'color_members.css', search_string: '', loading: false, rolesList: [] };
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {

    }

    fetchData(state, instance) {
        // function for fetch data from database
        this.setState({ loading: true });
        requestData(
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            this.setState({
                rolesList: res.rows,
                pages: res.pages,
                loading: false,
                total_count: res.total_count
            });
        });
    }


    render() {
        //const {data, pages, loading} = this.state;
        var complete_address = '';
        const columns = [
            {
                Header: 'HCM-ID', accessor: 'ocs_id', filterable: false, maxWidth: 95,
                headerClassName: 'Th_class_d1',
                className: '_align_c__',
                Cell: props => <span>
                    <Link className="inherit-color" to={"/admin/support_worker/about/" + props.original.ocs_id}>{props.original.ocs_id}</Link>
                </span>
            },
            {
                Header: props => <span>Name: <small>Preferred/First/Last</small></span>, accessor: 'firstname', filterable: false, maxWidth: 190,
                className: '_align_c__',
                Cell: props => <span>{props.original.firstname}</span>
            },
            {
                Header: 'Home address', accessor: 'home_address', filterable: false, sortable: false,
                headerClassName: 'Th_class_d1',
                className: '_align_c__',
                Cell: props => <span>
                    {(props.original.completeAddress != '') ? props.original.completeAddress : ''}
                </span>
            },
            { Header: 'Department', className: '_align_c__', accessor: 'department', filterable: false, sortable: false, maxWidth: 190, Cell: props => <span className="_align_c__"><div className='ellipsis_line__'>{props.original.department}</div></span> },
            {
                Header: 'Phone', className: '_align_c__', accessor: 'phone', filterable: false, maxWidth: 190,
                headerClassName: 'Hclass_d1',
                className: '_align_c__',
                Cell: props => <span>{props.value}</span>
            },
            { Header: 'G', className: '_align_c__', accessor: 'gender', filterable: false, maxWidth: 50, Cell: props => <span>{props.original.gender.charAt(0)}</span> },
            {
                Header: <TotalShowOnTable color="no" countData={this.state.total_count} />, accessor: 'id', filterable: false,
                Cell: props => <span className="d-flex w-100 justify-content-center align-item-center">
                    {(props.original.completeAddress.trim() != '') ?
                        <a class="short_buttons_01" title={MemberPageIconTitle.par_address_icon} href={"https://www.google.com/maps/place/" + encodeURIComponent(props.original.completeAddress)} target="_blank">Location</a>
                        : ''}
                    <span class="short_buttons_01 ml-3">Call</span>
                    <Link title={MemberPageIconTitle.par_details_icon} className="short_buttons_01 ml-3" to={"/admin/support_worker/about/" + props.original.ocs_id + "/details"}>View</Link>
                </span>, minWidth:170
            }

        ]
        return (
            <div>

                <div className="Orange">

                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="listing_table PL_site th_txt_center__ odd_even_tBL  line_space_tBL H-Set_tBL">
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    columns={columns}
                                    manual
                                    data={this.state.rolesList}
                                    pages={this.state.pages}
                                    loading={this.state.loading}
                                    onFetchData={this.fetchData}
                                    filtered={this.props.filtered}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    noDataText="No member"
                                    minRows={2}
                                    previousText={<span className="icon icon-arrow-left privious"></span>}
                                    nextText={<span className="icon icon-arrow-right next"></span>}

                                    getTrProps={(state, rowInfo, column, instance) => ({
                                        onClick: e => this.setState({ ocs_id: rowInfo.original.ocs_id }, () => { })
                                    })}
                                    showPagination={this.state.rolesList.length > PAGINATION_SHOW ? true : false}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MembersList;

