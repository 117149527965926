import React from 'react';
import Select from 'react-select-plus';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import ReactTable from 'react-table';
import Pagination from "service/Pagination.js";
import { ROUTER_PATH } from '../../../config';
class Enquiries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        const selectOpt = [
            { value: '1', label: 'opt1' }
        ]

        const columns = [
            {
                accessor: "status",
                headerClassName: '_align_c__ header_cnter_tabl',
                Header: () =>
                    <div>
                        <div className="ellipsis_line__"></div>
                    </div>
                ,
                width: 30,
                className: '_align_c__ no_bor_r',
                Cell: props => <span>{props.value ? <span className={'tble_circle'}></span> : ''}</span>
            },
            {
                accessor: "date",
                headerClassName: '_align_c__ header_cnter_tabl',
                Header: () =>
                    <div>
                        <div className="ellipsis_line__">Date Received</div>
                    </div>
                ,
                className: '_align_c__',
                Cell: props => <span>{props.value}</span>
            },
            {
                accessor: "enquiry_number",
                sortable: false,
                headerClassName: '_align_c__ header_cnter_tabl',
                Header: () =>
                    <div>
                        <div className="ellipsis_line__">Enquiry Number</div>
                    </div>,
                className: '_align_c__',
                Cell: props => <span>{props.value}</span>
            },
            {
                accessor: "title",
                sortable: false,
                headerClassName: '_align_c__ header_cnter_tabl',
                Header: () =>
                    <div>
                        <div className="ellipsis_line__">Enquiry Title</div>
                    </div>,
                className: '_align_c__',
                Cell: props => <span>{props.value}</span>
            },
            {
                accessor: "priority",
                sortable: false,
                headerClassName: '_align_c__ header_cnter_tabl',
                Header: () =>
                    <div>
                        <div className="ellipsis_line__">Priority</div>
                    </div>,
                className: '_align_c__',
                Cell: props => <span>{props.value}</span>
            },
            {
                accessor: "category",
                sortable: false,
                headerClassName: '_align_c__ header_cnter_tabl',
                Header: () =>
                    <div>
                        <div className="ellipsis_line__">Category</div>
                    </div>,
                className: '_align_c__',
                Cell: props => <span>{props.value}</span>
            },
            {
                accessor: "actions",
                sortable: false,
                headerClassName: '_align_c__ header_cnter_tabl',
                Header: () =>
                    <div>
                        <div className="ellipsis_line__">Actions</div>
                    </div>,
                className: '_align_c__',
                Cell: props => (

                    <div>

                        {
                            props.original.status2 == 'active' ?
                                <span className="short_buttons_01 btn_color_avaiable pointer-events-none mr-2">Active</span>
                                :
                                props.original.status2 == 'received' ?
                                    <span className="short_buttons_01 btn_color_unavailable pointer-events-none mr-2">Received</span>
                                    :
                                    <span className="short_buttons_01 btn_color_archive pointer-events-none mr-2">Closed</span>
                        }



                        <Link to={ROUTER_PATH + 'admin/helpdesk/view_enquiry/' + props.original.id}><span className="short_buttons_01  pointer-events-none mr-2">View</span></Link>
                        <span className="short_buttons_01  pointer-events-none">Delete</span>
                        
                    </div>
                )
            },
        ]

        const enquiriesData = [
            {id:'1', status: true, date: '12/05/2019', enquiry_number: '15', title: 'lorem ipsum', priority: 'High', category: 'Finance', status2: 'active' },
            {id:'2',  status: false, date: '12/05/2019', enquiry_number: '13', title: 'lorem ipsum', priority: 'Medium', category: 'Marketing', status2: 'received' },
            {id:'3',  status: true, date: '12/05/2019', enquiry_number: '14', title: 'lorem ipsum', priority: 'Low', category: 'CRM', status2: 'closed' }
        ]
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-lg-12">
                        <div className=" py-4">
                            <span className="back_arrow invisible">
                                <a href="/admin/crm/participantadmin"><span className="icon icon-back1-ie"></span></a>
                            </span>
                        </div>


                        <div className="by-1 ">
                            <div className="row d-flex  py-4">
                                <div className="col-lg-10 col-md-8">
                                    <div className="h-h1 color">{this.props.pageTypeParms}</div>
                                </div>
                                <div className="col-lg-2 col-md-4 d-flex align-self-center">
                                    <Link to={ROUTER_PATH + 'admin/helpdesk/create_enquiry'} className="C_NeW_BtN w-100">
                                        <span>Create New Enquiry</span>
                                        <i className="icon icon icon-add-icons"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="bb-1 mb-4">
                            <div className="row sort_row1-- after_before_remove">
                                <div className="col-lg-6 col-md-8 col-sm-8 ">
                                    <form id="srch_" autoComplete="off" method="post">
                                        <div className="search_bar right srchInp_sm actionSrch_st">
                                            <input type="text" className="srch-inp" placeholder="Search.." name="srch_box" /><i className="icon icon-search2-ie" onClick={this.searchData}></i>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-4 ">
                                    <div className="sLT_gray left left-aRRow">

                                        <Select
                                            name="form-field-name"
                                            value={this.state.search_key}
                                            simpleValue={true}
                                            options={selectOpt}
                                            onChange={(e) => this.setState({ search_key: e })}
                                            clearable={false}
                                            searchable={false}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-4 ">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="Fil_ter_ToDo">
                                                <label>From</label>
                                                <span  className={'cust_date_picker'}>
                                                    <DatePicker
                                                        placeholderText="00/00/0000"
                                                        autoComplete={'off'}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="Fil_ter_ToDo">
                                                <label>To</label>
                                                <span  className={'cust_date_picker right_0_date_piker'}>
                                                    <DatePicker
                                                        placeholderText="00/00/0000"
                                                        autoComplete={'off'}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-lg-12 ">
                        <div className="listing_table PL_site th_txt_center__ odd_even_tBL  line_space_tBL H-Set_tBL">
                            <ReactTable
                                manual
                                filtered={this.state.filtered}
                                defaultPageSize={10}
                                pages={this.state.pages}
                                loading={this.state.loading}
                                PaginationComponent={Pagination}
                                minRows={1}
                                data={enquiriesData}
                                onFetchData={this.fetchPayRateList}
                                columns={columns}
                                noDataText="No Record Found"
                                ref={this.reactTable}
                                previousText={<span className="icon icon-arrow-left privious"></span>}
                                nextText={<span className="icon icon-arrow-right next"></span>}
                                showPagination={true}
                                className="-striped -highlight"
                                noDataText="No Enquiries found"

                            />
                        </div>
                    </div>

                </div>


            </React.Fragment>
        )
    }
}

export default Enquiries;