const initialState = {
  approval_details: {
   id:''
},
user_details:{id:''},
activePage:{pageTitle:'',pageType:''}
}


const UserDetailsData = (state = initialState, action) => {
  switch (action.type) {
    case 'set_user_approval_data':
    return { ...state, approval_details: action.detailsData };

    case 'set_active_page_user':
    return {...state, activePage: action.value};

    case 'set_user_managment_data':
    return { ...state, user_details: action.detailsData };

    default:
            return state
        }
    }
export default UserDetailsData