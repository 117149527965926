import React from 'react';
import jQuery from "jquery";
import { ROUTER_PATH } from 'config.js';
import { Link } from 'react-router-dom';
import { postData, checkItsNotLoggedIn, getStateList, handleAddShareholder, handleRemoveShareholder, handleChangeSelectDatepicker, handleShareholderNameChange, handleChangeChkboxInput, selectFilterOptions, handleCheckboxValue, postImageData, queryOptionData, googleAddressFill } from '../../../service/common.js';
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import { isParentOrg, orgAddressCategory, orgGst, orgTax, orgBillPayBy } from 'dropdown/Orgdropdown.js';
import "react-placeholder/lib/reactPlaceholder.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastUndo } from 'service/ToastUndo.js';
import ReactGoogleAutocomplete from './../externl_component/ReactGoogleAutocomplete';
import ScrollArea from "react-scrollbar";
import SimpleBar from 'simplebar-react';

import Modal from 'react-bootstrap/lib/Modal';
import { confirmAlert } from 'react-confirm-alert';

const getOptionsSuburb = (input, state) => {
    return queryOptionData(input, 'common/Common/get_suburb', { query: input, state: state }, 3);
}

const getParentOrg = (input) => {
    return queryOptionData(input, 'organisation/OrgDashboard/get_parent_org_name', { query: input });
}

const getOrgABN = (input) => {
    return queryOptionData(input, 'organisation/OrgDashboard/get_abn_name', { query: input }, 4);
}

const getSiteOrg = (input) => {
    return queryOptionData(input, 'organisation/OrgDashboard/get_site_org', { query: input }, 3);
}


class OrganisationCreate extends React.Component {
    constructor(props) {
        super(props);
        checkItsNotLoggedIn(ROUTER_PATH);

        this.state = {
            is_parent_org: props.is_parent_org,
            parent_organisation_id: props.parent_organisation_id,
            parent_organisation: props.parent_organisation,
            parent_organisation_select: props.parent_organisation,
            loading: false,
            submit_clicked: false,
            completeAddress: [{ 'street': '', 'suburb': '', 'state': '', postal_code: '', address_category: '' }],
            OrganisationPh: [{ 'phone': '' }],
            OrganisationEmail: [{ 'email': '' }],
            OrganisationKeyContactPh: [{ 'phone': '' }],
            OrganisationKeyContactEmail: [{ 'email': '' }],
            OrganisationBillingContactPh: [{ 'phone': '' }],
            OrganisationBillingContactEmail: [{ 'email': '' }],
            OrgRequirement: [],
            parent_organisation_abn: '',
            organisation_gst: 1,
            organisation_payroll_tax: 0,
            abn_enable: false,
            organisation_abn: '',
            selectedFile: null,
            iskeyBillSame: false,
            site_org: [],
            site_org_select: [],
            OrganisationStatus: 1,
            subOrgNameTitle: 'Sub-Organisation Name (legal)',
            subOrgAbnTitle: 'Sub-Organisation ABN',
            subOrgAddressTitle: 'Sub-Organisation Address (Primary)',
        };
    }

    fileChangedHandler = (event) => {
        let type = event.target.files[0].type;
        type = type != '' && type != null ? type.toLowerCase() : type;
        if (!type.match('image/jpg') && !type.match('image/png') && !type.match('image/jpeg')) {
            toast.error(<ToastUndo message={"Only allowed jpg and png type image"} showType={'e'} />, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            event.target.value = '';

        } else {
            this.setState({ selectedFile: event.target.files[0], filename: event.target.files[0].name })
        }
    }

    componentDidMount() {
        this.getOrgRequirement();
        getStateList().then((result) => {
            var stateList = result;
            this.setState({ stateList: stateList }, () => { });
        });
    }

    getOrgRequirement = () => {
        postData('common/common/get_org_requirement', {}).then((result) => {
            if (result.status) {
                this.setState({ OrgRequirement: result.data }, () => { });
            }
        });
    }

    orgOnChange = (e, type) => {
        var state = {};
        if (type == 'org') {
            state['organisation_name'] = e;
            state['organisation_abn'] = e != null && e.hasOwnProperty('abn') ? e.abn : '';
        } else {
            state['parent_organisation_select'] = e != null && e.hasOwnProperty('abn') ? { label: e.label, value: e.value } : '';
            state['parent_organisation_abn'] = e != null && e.hasOwnProperty('abn') ? e.abn : '';
            state['parent_organisation_id'] = e != null && e.hasOwnProperty('value') ? e.value : '';
        }
        this.setState(state, () => {

            if (type != 'org')
                this.getOrgBillingDetail();
        });
    }

    handleAddressChange = (idx, value, fieldName, fieldType) => {
        var state = {};
        var List = this.state['completeAddress'];
        List[idx][fieldName] = value;

        if (fieldName == 'state') {
            List[idx]['suburb'] = ''
            List[idx]['postal_code'] = ''
        }

        if (fieldName == 'suburb' && value) {
            List[idx][fieldName] = value.value
            List[idx]['postal_code'] = value.postcode
        }
        state['completeAddress'] = List;
        this.setState(state);
    }

    checkParentexist = () => {
        //Check in case of organisation
        if (this.state.is_parent_org == 0) {
            if (this.state.parent_organisation_select != '' && this.state.parent_organisation_select != null && this.state.parent_organisation_select.hasOwnProperty('value') && this.state.parent_organisation_select.value) {
                this.setState({ isParentModalShow: false, parent_organisation: this.state.parent_organisation_select }, () => { });
                return true;
            }
            else {
                toast.error(<ToastUndo message={"Please select Parent organisation to continue."} showType={'e'} />, {
                    // toast.error("Please select Parent organisation to continue.", {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            }
        }
        else
            return true;
    }

    addSiteData = () => {
        if (this.state.site_org_select.length > 0) {
            let siteSelected = this.state.site_org_select;
            this.setState({ isSiteModalShow: false, site_org: siteSelected });
            return true;
        } else {
            toast.error(<ToastUndo message={"Please select atleast one site to add."} showType={'e'} />, {
                // toast.error("Please select atleast one site to add.", {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
        }
    }

    removeSiteData = (removeIndex) => {
        let data = this.state.site_org;
        if (typeof data[removeIndex] != undefined) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <div className="confi_header_div">
                                <h3>Confirmation</h3>
                                <span className="icon icon-cross-icons" onClick={() => {
                                    onClose();
                                }}></span>
                            </div>
                            <p><span>Are you sure you want to remove this item?</span></p>
                            <div className="confi_but_div">
                                <button className="Confirm_btn_Conf" onClick={
                                    () => {
                                        data.splice(removeIndex, 1);
                                        this.setState({ site_org: data, site_org_select: data });
                                        onClose();
                                    }}>Confirm</button>
                                <button className="Cancel_btn_Conf" onClick={
                                    () => {
                                        onClose();
                                    }}> Cancel</button>
                            </div>
                        </div>
                    )
                }
            })
        }
    }
    //

    handleSaveOrg = (e) => {
        //console.log('props', this.props);
        //console.log('parent_organisation_id', this.state.parent_organisation_id);
        this.setState({ submit_clicked: true });
        e.preventDefault();
        jQuery("#org_form").validate({ /* */ });
        if (jQuery("#org_form").valid()) {
            let parentOrg = true;

            if (this.state.is_parent_org == 0 && (this.state.parent_organisation_id == '' || this.state.parent_organisation_id == null)) {
                parentOrg = false;
            }
            if (parentOrg) {
                this.setState({ loading: true });
                const formData = new FormData();
                formData.append('myFile', this.state.selectedFile, this.state.selectedFile.name);
                formData.append('current_state', JSON.stringify(this.state));
                postImageData('organisation/OrgDashboard/create_org', formData).then((result) => {
                    if (result.status) {
                        this.setState({ loading: false });
                        toast.success(<ToastUndo message={result.msg} showType={'s'} />, {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true
                        });
                        if (!this.props.isShowModal) {
                            window.location = '/admin/organisation/overview/' + result.orgId;
                        } else if (this.props.isShowModal) {
                            this.props.closePopup(true);
                            setTimeout(() => { toast.dismiss(); }, 2000);
                        }

                    } else {
                        this.setState({ loading: false });
                        toast.error(<ToastUndo message={result.error} showType={'e'} />, {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true
                        });
                    }
                });
            } else if (!parentOrg) {
                toast.error(<ToastUndo message={'Please select Parent organisation to continue'} showType={'e'} />, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            }
        }
    }

    getOrgBillingDetail = () => {
        postData('organisation/Organisation/get_org_billing_detail', { parentOrg: this.state.parent_organisation_id }).then((result) => {
            if (result.status) {
                this.setState({
                    bill_pay_by:1,
                    bill_contact_fname: (result.data.name) ? result.data.name : '',
                    bill_contact_lname: (result.data.lastname) ? result.data.lastname : '',
                    bill_contact_position: (result.data.position) ? result.data.position : '',
                    bill_contact_department: (result.data.department) ? result.data.department : '',
                    OrganisationBillingContactPh: result.data.OrganisationPh,
                    OrganisationBillingContactEmail: result.data.OrganisationEmail,
                }, () => { });
            }
        });
    }

    render() {
        const classMd = this.props.isShowModal ? ' col-lg-12 col-sm-12' : ' col-lg-12 col-sm-12 ';
        const classMdInput = this.props.isShowModal ? 'w-20-lg col-lg-2 col-sm-3' : 'w-20-lg col-lg-2 col-sm-3 ';
        const classMdMemberReq = this.props.isShowModal ? ' col-lg-12 col-sm-12 ' : ' col-lg-12 col-sm-12 ';
        const classMdPullRight = this.props.isShowModal ? ' pull-right ' : ' ';
        const classMdState = this.props.isShowModal ? ' w-10-lg col-lg-1 col-sm-2' : 'w-10-lg col-lg-1 col-sm-2 ';
        return (
            <div>
                {this.props.isShowModal ? <React.Fragment /> : ''}
                <form id="org_form" method="post" autoComplete="off" >

                    <SimpleBar style={this.props.isShowModal ?
                        { maxHeight: '670px', overflowX: 'hidden', paddingLeft: '15px', paddingRight: '15px' } :
                        { padding: "0", maxHeight: 'auto', overflowX: 'hidden' }}
                        forceVisible={false}>

                        {this.props.isShowModal ? <React.Fragment /> : <React.Fragment >
                            <div className="row">
                                <div className={classMd + " P_15_TB"}>
                                    <Link to="/admin/organisation/dashboard"><span className="icon icon-back-arrow back_arrow"></span></Link>
                                </div>
                                <div className={classMd}><div className="bor_T"></div></div>
                            </div>

                            <div className="row _Common_He_a">
                                <div className="col-lg-12 col-sm-12">
                                    <h1 className="color">Creating New Organisation</h1>
                                </div>
                            </div>
                            <div className="row"><div className={classMd}><div className="bor_T"></div></div></div>
                        </React.Fragment>
                        }
                        <div className="row mt-5">
                            {this.props.isShowModal ? <React.Fragment /> : <div className="w-20-lg col-lg-2 col-sm-3">
                                <label className="label_2_1_1">Is This a Parent Org?</label>
                                <Select name="is_parent_org" required={true} simpleValue={true} searchable={false} clearable={false} options={isParentOrg()} placeholder="" value={this.state.is_parent_org} onChange={(e) => this.setState({
                                    'is_parent_org': e,
                                    parent_organisation_select: '',
                                    parent_organisation_abn: '',
                                    subOrgNameTitle: 'Organisation Name (legal)',
                                    subOrgAbnTitle: 'Organisation ABN',
                                    subOrgAddressTitle: 'Organisation Address (Primary)',
                                })} />
                            </div>}

                            {(this.state.is_parent_org == 0 && !this.props.isShowModal) ?
                                <span>
                                    <div className="w-40-lg col-lg-4 col-sm-4">
                                        <label className="label_2_1_1">Parent Organisation Name (Legal):</label>
                                        <span className="required">
                                            <div className="search_icons_right modify_select">
                                                <Select.Async
                                                    cache={false}
                                                    name="form-field-name"
                                                    loadOptions={(e) => getParentOrg(e)}
                                                    clearable={false}
                                                    placeholder='Search'
                                                    value={this.state.parent_organisation_select}
                                                    onChange={(e) => this.orgOnChange(e, 'parentOrg')}
                                                    className="default_validation search_icons_right modify_select"
                                                    required
                                                />
                                            </div>
                                        </span>
                                    </div>
                                    <div className={classMdInput}>
                                        <label className="label_2_1_1">Parent Org's ABN:</label>
                                        <input type="text" name="parent_organisation_abn" placeholder="00 000 000 000" autoComplete="new-password" disabled={(this.state.abn_enable) ? false : true} value={this.state.parent_organisation_abn} />
                                    </div>
                                </span>
                                : ''}
                        </div>


                        <div className="row mt-5">
                            <div className="w-40-lg col-lg-4 col-sm-4">
                                <label className="label_2_1_1">{this.state.subOrgNameTitle}:</label>
                                <span className="required">
                                    <div className="search_icons_right modify_select">
                                        <Select.Async
                                            name="organisation_name"
                                            loadOptions={(e) => getOrgABN(e)}
                                            clearable={false}
                                            placeholder='Search'
                                            value={this.state.organisation_name}
                                            onChange={(e) => this.orgOnChange(e, 'org')}
                                            className="default_validation search_icons_right modify_select"
                                            filterOptions={selectFilterOptions}
                                            cache={false}
                                        />
                                    </div>
                                </span>
                            </div>
                            <div className={classMdInput}>
                                <label className="label_2_1_1">{this.state.subOrgAbnTitle}:</label>
                                <span className="required">
                                    <input type="text" name="organisation_abn" placeholder="00 000 000 000" onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" disabled={(this.state.abn_enable) ? false : true} value={this.state.organisation_abn} />
                                </span>
                            </div>

                            <div className="w-20-lg col-lg-2 col-sm-3 ">
                                <label className="label_2_1_1">Logo:</label>
                                <span className="required">
                                    <div className="upload_btn">
                                        <label className="btn-file">
                                            <div className="v-c-btn1"><span>Browse for your file</span>
                                                <i className="icon icon-attach-im" aria-hidden="true"></i>
                                            </div>
                                            <input className="p-hidden" type="file" name="myFile" onChange={this.fileChangedHandler} required />
                                        </label>
                                    </div>
                                </span>
                                {(this.state.filename) ? <p>File Name: <small>{this.state.filename}</small></p> : ''}
                            </div>
                        </div>

                        {this.state.completeAddress.map((value, idx) => (
                            <div className="row mt-5 d-flex flex-wrap" key={idx}>
                                <div className="w-40-lg col-lg-4 col-sm-4">
                                    <label className="label_2_1_1">{this.state.subOrgAddressTitle}:</label>
                                    <span className="required">
                                        <div className="input_plus__ mb-1">

                                            <ReactGoogleAutocomplete className="input_f"
                                                required={true}
                                                data-msg-required="Add address"
                                                name={'street' + idx}
                                                onPlaceSelected={(place) => googleAddressFill(this, idx, 'completeAddress', 'street', place)}
                                                types={['address']}
                                                returntype={'array'}
                                                value={value.street || ''}
                                                onChange={(evt) => this.handleAddressChange(idx, evt.target.value, 'street')}
                                                onKeyDown={(evt) => this.handleAddressChange(idx, evt.target.value, 'street')}
                                                componentRestrictions={{ country: "au" }}
                                            />

                                        </div>
                                    </span>
                                </div>
                                <div className={classMdState}>
                                    <label className="label_2_1_1">State:</label>
                                    

                                    <Select className="" required={true}
                                        simpleValue={true}
                                        name={'state' + idx}
                                        options={this.state.stateList}
                                        clearable={false}
                                        searchable={false}
                                        onChange={(e) => this.handleAddressChange(idx, e, 'state')}
                                        value={value.state || ''}
                                    />
                                </div>

                                <div className={classMdInput}>
                                    <label className="label_2_1_1">Suburb:</label>
                                    <span className="required modify_select">
                                        <Select.Async cache={false} clearable={false} name={'suburb' + idx} className="default_validation search_icons_right modify_select" required={true}
                                            value={{ label: value.suburb, value: value.suburb }} disabled={(value.state) ? false : true} loadOptions={(val) => getOptionsSuburb(val, value.state)} onChange={(e) => this.handleAddressChange(idx, e, 'suburb')}
                                            placeholder="Please Select" />
                                    </span>
                                </div>

                                <div className="w-10-lg col-lg-1 col-sm-2 ">
                                    <label className="label_2_1_1">Postcode:</label>
                                    <span className="required">
                                        <input placeholder="0000" className="text-center" type="text" name={'postal_code' + idx} data-rule-required="true" value={value.postal_code} onChange={(e) => this.handleAddressChange(idx, e.target.value, 'postal_code')} autoComplete="new-password" data-rule-number="true" minLength="4" maxLength="4" data-rule-postcodecheck="true" />
                                    </span>
                                </div>
                                <div className={classMdInput}>
                                    <label className="label_2_1_1">Category:</label>
                                    <Select name="address_category" required={true} simpleValue={true} searchable={false} clearable={false} value={value.address_category} onChange={(e) => this.handleAddressChange(idx, e, 'address_category')} options={orgAddressCategory()} placeholder="Please Select" />
                                </div>

                                <div className="col-lg-1 col-sm-2 P_20_T" style={{ display: 'none' }}>
                                    {idx > 0 ? <span onClick={(e) => handleRemoveShareholder(this, e, idx, 'completeAddress')} className="button_plus__">
                                        <i className="icon icon-decrease-icon Add-2-2" ></i>
                                    </span> : (this.state.completeAddress.length == 3) ? '' : <span className="button_plus__" onClick={(e) => handleAddShareholder(this, e, 'completeAddress', value)}>
                                        <i className="icon icon-add-icons Add-2-1" ></i>
                                    </span>
                                    }
                                </div>
                            </div>
                        ))}

                        <div className="row mt-5">
                            <div className="col-lg-12">
                                <div className="pAY_heading_01 by-1"><div className="tXT_01"> New Organisation - Contact Details:</div></div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className={classMdInput} >
                                {this.state.OrganisationPh.map((value, idx) => (
                                    <div className="mb-3" key={idx + 1}>
                                        <label className="label_2_1_1">Phone ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                        <span className="required">
                                            <div className="input_plus__ mb-1">
                                                <input type="text" className="input_f distinctOrgPh" placeholder="Can Include Area Code" value={value.phone} name={'org_phone' + idx} required onChange={(e) => handleShareholderNameChange(this, 'OrganisationPh', idx, 'phone', e.target.value)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctOrgPh"]' data-rule-phonenumber data-msg-notequaltogroup="Please enter a unique contact number" />
                                                {idx > 0 ? <div className="btn_0_type" onClick={(e) => handleRemoveShareholder(this, e, idx, 'OrganisationPh')} >
                                                    <i className="icon icon-decrease-icon Add-2" ></i>
                                                </div> : (this.state.OrganisationPh.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => handleAddShareholder(this, e, 'OrganisationPh', value)}>
                                                    <i className="icon icon-add-icons Add-1" ></i>
                                                </div>
                                                }
                                            </div>
                                        </span>
                                    </div>
                                ))}
                            </div>

                            <div className={classMdInput}>
                                {
                                    this.state.OrganisationEmail.map((value, idx) => (
                                        <div className="mb-3" key={idx + 1}>
                                            <label className="label_2_1_1">Email ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                            <span className="required">
                                                <div className="input_plus__ mb-1">
                                                    <input type="email" className="input_f distinctEmail" placeholder="example@example.com" value={value.email} name={'org_email' + idx} required onChange={(e) => handleShareholderNameChange(this, 'OrganisationEmail', idx, 'email', e.target.value)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctEmail"]' />
                                                    {idx > 0 ? <div className="btn_0_type" onClick={(e) => handleRemoveShareholder(this, e, idx, 'OrganisationEmail')} >
                                                        <i className="icon icon-decrease-icon Add-2" ></i>
                                                    </div> : (this.state.OrganisationEmail.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => handleAddShareholder(this, e, 'OrganisationEmail', value)}>
                                                        <i className="icon icon-add-icons Add-1" ></i>
                                                    </div>
                                                    }
                                                </div>
                                            </span>
                                        </div>
                                    ))}
                            </div>

                            <div className={classMdInput}>
                                <label className="label_2_1_1">Website:</label>
                                <span className="required">
                                    <input type="text" placeholder="examplecompany.com.au" name="organisation_website" required onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" />
                                </span>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-lg-12">
                                <div className="pAY_heading_01 by-1"><div className="tXT_01">Key Contact -  Details:</div></div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className={classMdInput}>
                                <label className="label_2_1_1">First Name:</label>
                                <span className="required">
                                    <input type="text" name="key_contact_fname" placeholder="First" required onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" />
                                </span>
                            </div>
                            <div className={classMdInput}>
                                <label className="label_2_1_1">Last Name:</label>
                                <span className="required">
                                    <input type="text" placeholder="Last" name="key_contact_lname" required onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" />
                                </span>
                            </div>
                            <div className={classMdInput}>
                                <label className="label_2_1_1">Position:</label>
                                <span className="required">
                                    <input type="text" placeholder="EG. CEO" required name="key_contact_position" onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" />
                                </span>
                            </div>
                            <div className={classMdInput}>
                                <label className="label_2_1_1">Department:</label>
                                <input type="text" placeholder="EG. Accounts" name="key_contact_department" onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className={classMdInput}>
                                {this.state.OrganisationKeyContactPh.map((value, idx) => (
                                    <div className="mb-3" key={idx + 1}>
                                        <label className="label_2_1_1">Phone ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                        <span className="required">
                                            <div className="input_plus__ mb-1">
                                                <input type="text" className="input_f distinctPhone" placeholder="Can Include Area Code" value={value.phone} name={'keycontact_phone' + idx} required onChange={(e) => handleShareholderNameChange(this, 'OrganisationKeyContactPh', idx, 'phone', e.target.value)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctPhone"]' data-rule-phonenumber data-msg-notequaltogroup="Please enter a unique contact number" />
                                                {idx > 0 ? <div className="btn_0_type" onClick={(e) => handleRemoveShareholder(this, e, idx, 'OrganisationKeyContactPh')} >
                                                    <i className="icon icon-decrease-icon Add-2" ></i>
                                                </div> : (this.state.OrganisationKeyContactPh.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => handleAddShareholder(this, e, 'OrganisationKeyContactPh', value)}>
                                                    <i className="icon icon-add-icons Add-2" ></i>
                                                </div>
                                                }
                                            </div>
                                        </span>
                                    </div>
                                ))}
                            </div>

                            <div className={classMdInput}>
                                {
                                    this.state.OrganisationKeyContactEmail.map((value, idx) => (
                                        <div className="mb-3" key={idx + 1}>
                                            <label className="label_2_1_1">Email ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                            <span className="required">
                                                <div className="input_plus__ mb-1">
                                                    <input type="email" className="input_f distinctEmailkey" placeholder="example@example.com" value={value.email} name={'billcontact_email' + idx} required onChange={(e) => handleShareholderNameChange(this, 'OrganisationKeyContactEmail', idx, 'email', e.target.value)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctEmailkey"]' />
                                                    {idx > 0 ? <div className="btn_0_type" onClick={(e) => handleRemoveShareholder(this, e, idx, 'OrganisationKeyContactEmail')} >
                                                        <i className="icon icon-decrease-icon icon_cancel_1 Add-2" ></i>
                                                    </div> : (this.state.OrganisationKeyContactEmail.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => handleAddShareholder(this, e, 'OrganisationKeyContactEmail', value)}>
                                                        <i className="icon icon-add-icons Add-1" ></i>
                                                    </div>
                                                    }
                                                </div>
                                            </span>
                                        </div>
                                    ))}
                            </div>

                            <div className="col-lg-4 col-sm-3 P_10_TB">
                                <div className="row P_15_TB">
                                    <span className="col-sm-12 mt-2 include_box">
                                        <input type="checkbox" className="checkbox_big" id="Include" name="iskeyBillSame" checked={this.state['iskeyBillSame']} value={this.state.iskeyBillSame || ''}
                                            onChange={(e) => this.setState({ iskeyBillSame: e.target.checked }, () => {
                                                if (this.state.iskeyBillSame) {
                                                    this.setState({
                                                        bill_contact_fname: this.state.key_contact_fname,
                                                        bill_contact_lname: this.state.key_contact_lname,
                                                        bill_contact_position: this.state.key_contact_position,
                                                        bill_contact_department: this.state.key_contact_department,
                                                        OrganisationBillingContactPh: this.state.OrganisationKeyContactPh,
                                                        OrganisationBillingContactEmail: this.state.OrganisationKeyContactEmail,
                                                    }, function () {
                                                        if (this.state.submit_clicked) {
                                                            jQuery('.billDetails').valid();
                                                        }
                                                    });
                                                }
                                                else {
                                                    this.setState({
                                                        bill_contact_fname: '',
                                                        bill_contact_lname: '',
                                                        bill_contact_position: '',
                                                        bill_contact_department: '',
                                                        OrganisationBillingContactPh: [{ 'phone': '' }],
                                                        OrganisationBillingContactEmail: [{ 'email': '' }],
                                                    }, function () {
                                                        if (this.state.submit_clicked) {
                                                            jQuery('.billDetails').valid();
                                                        }
                                                    })
                                                }
                                            })} />
                                        <label className="w-100" htmlFor="Include"><span></span><small className="pl-1" style={{ fontSize: '15px' }}>Billing contact same as Key contact</small></label>
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div className="row mt-5">
                            <div className="col-lg-12">
                                <div className="pAY_heading_01 by-1"><div className="tXT_01">Billing Contact  -  Details:</div></div>
                            </div>
                        </div>

                        <div className="row mt-5">

                            {(this.state.is_parent_org == 0 && !this.props.isShowModal) ?
                                <div className={classMdInput}>
                                    <label className="label_2_1_1">Whom do you want to bill: </label>
                                    <span className="required default_validation">
                                        <Select name='bill_pay_by' options={orgBillPayBy()} simpleValue={true} searchable={true} clearable={false} value={this.state.bill_pay_by} placeholder="Types" onChange={(e) => this.setState({ 'bill_pay_by': e },()=>{
                                            if(this.state.bill_pay_by == 3){
                                                this.setState({
                                                    bill_contact_fname:'',
                                                    bill_contact_lname:'',
                                                    bill_contact_position:'',
                                                    bill_contact_department:'',
                                                    OrganisationBillingContactPh: [{ 'phone': '' }],
                                                    OrganisationBillingContactEmail: [{ 'email': '' }],
                                                })
                                            }                                            
                                        })} />
                                    </span>
                                </div> : ''}

                            <div className={classMdInput}>
                                <label className="label_2_1_1">First Name:</label>
                                <span className="required">
                                    <input type="text" className="billDetails" placeholder="First" required name="bill_contact_fname" value={this.state.bill_contact_fname || ''} onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" />
                                </span>
                            </div>
                            <div className={classMdInput}>
                                <label className="label_2_1_1">Last Name:</label>
                                <span className="required">
                                    <input type="text" className="billDetails" placeholder="Last" name="bill_contact_lname" required value={this.state.bill_contact_lname || ''} onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" />
                                </span>
                            </div>
                            <div className={classMdInput}>
                                <label className="label_2_1_1">Position:</label>
                                <span className="required">
                                    <input type="text" className="billDetails" placeholder="EG. CEO" required name="bill_contact_position" value={this.state.bill_contact_position || ''} onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" />
                                </span>
                            </div>
                            <div className={classMdInput}>
                                <label className="label_2_1_1">Department:</label>
                                <input type="text" className="billDetails" placeholder="EG. Accounts" name="bill_contact_department" value={this.state.bill_contact_department || ''} onChange={(e) => handleChangeChkboxInput(this, e)} autoComplete="new-password" />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className={classMdInput}>
                                {this.state.OrganisationBillingContactPh.map((value, idx) => (
                                    <div className="mb-3" key={idx + 1}>
                                        <label className="label_2_1_1">Phone ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                        <span className="required">
                                            <div className="input_plus__ mb-1">
                                                <input type="text" className="input_f distinctPhBill billDetails" placeholder="Can Include Area Code" value={value.phone} name={'billcontact_phone' + idx} required onChange={(e) => handleShareholderNameChange(this, 'OrganisationBillingContactPh', idx, 'phone', e.target.value)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctPhBill"]' data-rule-phonenumber data-msg-notequaltogroup="Please enter a unique contact number" />
                                                {idx > 0 ? <div className="btn_0_type" onClick={(e) => handleRemoveShareholder(this, e, idx, 'OrganisationBillingContactPh')} >
                                                    <i className="icon icon-decrease-icon Add-2" ></i>
                                                </div> : (this.state.OrganisationBillingContactPh.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => handleAddShareholder(this, e, 'OrganisationBillingContactPh', value)}>
                                                    <i className="icon icon-add-icons Add-1" ></i>
                                                </div>
                                                }
                                            </div>
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className={classMdInput}>
                                {
                                    this.state.OrganisationBillingContactEmail.map((value, idx) => (
                                        <div className="mb-3" key={idx + 1}>
                                            <label className="label_2_1_1">Email ({(idx == 0) ? 'Primary' : 'Secondary'}):</label>
                                            <span className="required">
                                                <div className="input_plus__ mb-1">
                                                    <input type="email" className="input_f distinctEmailBill billDetails" placeholder="example@example.com" value={value.email} name={'keycontact_email' + idx} required onChange={(e) => handleShareholderNameChange(this, 'OrganisationBillingContactEmail', idx, 'email', e.target.value)} autoComplete="new-password" data-rule-notequaltogroup='[".distinctEmailBill"]' />
                                                    {idx > 0 ? <div className="btn_0_type" onClick={(e) => handleRemoveShareholder(this, e, idx, 'OrganisationBillingContactEmail')} >
                                                        <i className="icon icon-decrease-icon Add-2" ></i>
                                                    </div> : (this.state.OrganisationBillingContactEmail.length == 3) ? '' : <div className="btn_0_type" onClick={(e) => handleAddShareholder(this, e, 'OrganisationBillingContactEmail', value)}>
                                                        <i className="icon icon-add-icons Add-1" ></i>
                                                    </div>
                                                    }
                                                </div>
                                            </span>
                                        </div>
                                    ))}

                            </div>

                            <div className={classMdInput}>
                                <label className="label_2_1_1">Payroll Tax:</label>
                                <span className="required">
                                    <div className="input_plus__ mb-1">
                                        <Select name="organisation_payroll_tax" required={true} simpleValue={true} searchable={false} clearable={false} options={orgTax()} value={this.state.organisation_payroll_tax} onChange={(e) => this.setState({ 'organisation_payroll_tax': e })} placeholder="Exempt" />
                                    </div>
                                </span>
                            </div>

                            <div className={classMdInput}>
                                <label className="label_2_1_1">GST:</label>
                                <span className="required">
                                    <div className="input_plus__ mb-1">
                                        <Select name="organisation_gst" required={true} simpleValue={true} searchable={false} clearable={false} options={orgGst()} value={this.state.organisation_gst} onChange={(e) => this.setState({ 'organisation_gst': e })} />
                                    </div>
                                </span>
                            </div>

                        </div>


                        <div className="row mt-5">
                            <div className="col-lg-12">
                                <div className="pAY_heading_01 by-1"><div className="tXT_01">Organisation Requirements:</div></div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className={classMdMemberReq}>
                                <label className="label_2_1_1">Member Requirements for This Org:</label>

                                <label htmlFor="requirement[]" className="error CheckieError" style={{ display: "block", width: "100%;", marginBottom: '0px' }} ></label>

                                <div className="Parent-List_2_ul">
                                    <div className="cstmSCroll1 FScroll">
                                        <ScrollArea
                                            speed={0.8}
                                            contentClassName="content"
                                            horizontal={false}
                                            style={{ paddingRight: '15px', maxHeight: "140px" }}
                                        >
                                            <ul className="List_2_ul">
                                                {
                                                    this.state.OrgRequirement.map((value, idxx) => (
                                                        <li className="w-20" key={idxx}>
                                                            <label className="radio_F1 check_F1 mb-0" style={{ width: 'auto' }}>
                                                                <input type="checkbox" name="requirement[]" required={true}
                                                                    id={value.value} onChange={(e) => handleCheckboxValue(this, 'OrgRequirement', idxx, 'checked')} />

                                                                <span className="checkround"></span>
                                                                <span className="txtcheck text_2_0_1">{value.label}</span>
                                                            </label>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </ScrollArea>
                                    </div>
                                </div>

                                {/* <div className="col-lg-12 col-sm-12 multiple_checkbox pt-3">
                                        {
                                            this.state.OrgRequirement.map((value, idxx) => (
                                                <span key={idxx}>
                                                    <input type="checkbox" className="checkbox1" id={value.value} name="requirement" onChange={(e) => handleCheckboxValue(this, 'OrgRequirement', idxx, 'checked')} />
                                                    <label htmlFor={value.value}><span></span>{value.label}</label>
                                                </span>
                                            ))}
                                    </div> */}

                            </div>
                        </div>



                        <div className="row mt-5">
                            <div className="col-lg-12">
                                <div className="pAY_heading_01 by-1"><div className="tXT_01">Attach & Add:</div></div>
                            </div>
                        </div>


                        <div className="row mt-5">
                            <div className={classMdInput}>
                                <span className="but ho" onClick={() => this.setState({ isSiteModalShow: true })} >Add Sites</span>

                                {this.state.site_org.length > 0 ? <div className='Select_MSG_Show'>
                                    <div className="Select_MSG_Show_scroll">
                                        {this.state.site_org.map((value, index) => (
                                            <div key={index}><i className="icon icon-close" onClick={(e) => { this.removeSiteData(index) }}></i> {value.label}</div>
                                        ))}
                                    </div>
                                </div> : <React.Fragment />}


                            </div>

                            {(this.state.is_parent_org == 0 && !this.props.isShowModal) ?
                                <div className={classMdInput}>
                                    {/*<span className="but ho" onClick={() => this.setState({ isParentModalShow: true })}>Attach to Parent Org</span>*/}
                                    {this.state.parent_organisation != '' && this.state.parent_organisation != null && this.state.parent_organisation.hasOwnProperty('label') ? <div className='Select_MSG_Show'>
                                        <div className="Select_MSG_Show_scroll">
                                            <div >{this.state.parent_organisation.label}</div>

                                        </div>
                                    </div> : <React.Fragment />}
                                </div>
                                : <React.Fragment />}
                        </div>

                        {/*site add Popup*/}
                        <div>
                            <Modal className="modal fade-scale Modal_A  Modal_B Org_Module" show={this.state.isSiteModalShow} onHide={this.handleHide} container={this} aria-labelledby="myModalLabel" id="modal_2" tabIndex="-1" role="dialog" >
                                <Modal.Body>
                                    <div className="dis_cell">
                                        <div className="text text-left by-1 Popup_h_er_1"><span>Add Site:</span>
                                            <span data-dismiss="modal" aria-label="Close" className="close_i" onClick={() => this.setState({ isSiteModalShow: false, site_org_select: this.state.site_org })}><i className="icon icon-cross-icons"></i></span>
                                        </div>
                                        <div className="row P_15_T">
                                            <div className="col-sm-5">
                                                <label className="label_2_1_1">Name</label>
                                                <span className="required search_icons_right modify_select">
                                                    <Select.Async
                                                        cache={false}
                                                        name="form-field-name"
                                                        loadOptions={(e) => getSiteOrg(e)}
                                                        clearable={false}
                                                        closeOnSelect={false}
                                                        placeholder='Search'
                                                        multi={true}
                                                        value={this.state.site_org_select}
                                                        onChange={(e) => handleChangeSelectDatepicker(this, e, 'site_org_select')}
                                                        className="default_validation search_icons_right modify_select"
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-7"></div>
                                            <div className="col-sm-5">
                                                <input type="submit" className="but" value={'Add'} name="content" onClick={(e) => this.addSiteData()} />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                        {/*End site add Popup */}

                        {/*Parent Org Popup*/}
                        {/*this.props.isShowModal ? <React.Fragment /> :
                        <div>
                            <Modal className="modal fade-scale Modal_A  Modal_B Org_Module" show={this.state.isParentModalShow} onHide={this.handleHide} container={this} aria-labelledby="myModalLabel" id="modal_1" tabIndex="-1" role="dialog" >
                                <Modal.Body>
                                    <div className="dis_cell">
                                        <div className="text text-left by-1 Popup_h_er_1"><span>Adding Parent Organisation:</span>
                                            <span data-dismiss="modal" aria-label="Close" className="close_i" onClick={() => this.setState({ isParentModalShow: false, parent_organisation_select: this.state.parent_organisation })}><i className="icon icon-cross-icons"></i></span>
                                        </div>
                                        <div className="row P_15_T">
                                            <div className="col-sm-5">
                                                <label className="label_2_1_1">Name</label>
                                                <span className="required search_icons_right modify_select">
                                                    <Select.Async
                                                        cache={false}
                                                        name="form-field-name"
                                                        loadOptions={(e) => getParentOrg(e)}
                                                        clearable={false}
                                                        placeholder='Search'
                                                        value={this.state.parent_organisation_select}
                                                        onChange={(e) => handleChangeSelectDatepicker(this, e, 'parent_organisation_select')}
                                                        className="default_validation search_icons_right modify_select"
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-7"></div>
                                            <div className="col-sm-5">
                                                <input type="submit" className="but" value={'Add'} name="content" onClick={(e) => this.checkParentexist()} />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    */}
                    </SimpleBar>

                    {/*End Parent Org Popup*/}
                    <div className="row mt-5 d-flex justify-content-end">
                        <div className="col-lg-3 col-md-3 col-xs-12">
                            <button className="but_submit but_outline" onClick={(e) => this.setState({ OrganisationStatus: 2 }, () => { this.handleSaveOrg(e) })} disabled={this.state.loading}>Save Org as draft</button>
                        </div>
                        <div className="col-lg-3 col-md-3 col-xs-12">
                            <button className="but_submit" onClick={(e) => this.handleSaveOrg(e)} disabled={this.state.loading}>Save Org</button>
                        </div>

                    </div>
                </form>

            </div>
        );
    }
}
OrganisationCreate.defaultProps = {
    isShowModal: false,
    is_parent_org: 0,
    parent_organisation: '',
    closePopup: (e) => { }

}

export default OrganisationCreate;