import React, { Component } from 'react';
import jQuery from "jquery";
import _ from 'lodash'
import 'react-select-plus/dist/react-select-plus.css';

import { checkItsNotLoggedIn, postData, toastMessageShow, queryOptionData, checkLoginWithReturnTrueFalse, getPermission } from 'service/common.js';

import 'react-block-ui/style.css';

import 'service/jquery.validate.js';
import "service/custom_script.js";
import { connect } from 'react-redux';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import SLDSReactSelect from '../../../components/admin/salesforce/lightning/SLDSReactSelect.jsx';
import { SLDSISODatePicker } from '../salesforce/lightning/SLDSISODatePicker';
import { Input } from '@salesforce/design-system-react';


import settings from '@salesforce/design-system-react/components/settings';
import { utils } from '../../../helpers/utils';
import moment from 'moment'

const getOptionsMemberName = (e) => {
    return queryOptionData(e, "member/MemberRole/get_member_name_search", { query: e }, 2, 1);
}

const getRoles = (e) => {
    return queryOptionData(e, "item/document/get_role_name_search", { query: e }, 2, 1);
}

const getOptionsEmploymentName = (e) => {
    return queryOptionData(e, "member/MemberRole/get_member_employment_type_search", { query: e }, 2, 1);
}

class CreateMemberRoleModal extends Component {
    constructor(props) {
        super(props);
        checkItsNotLoggedIn();

        if (props.addModal) {
            this.state = {
                loading: false,
                isFetching: false,
                callAjax: false,
                member: { label: props.member.member_name, value: props.member.member_id },
                employment_type: '',
                employmentTypeOptions: '',
            }
        } else {
            let state = {
                loading: false,
                isFetching: false,
                callAjax: false,
                member: { label: this.props.member.member_name, value: this.props.member.member_id },
                role_details: { label: this.props.member.role_name, value: this.props.member.role_id },
                role_start_date: moment(this.props.member.role_start_time).format('YYYY-MM-DD 00:00:00'),
                role_start_time: moment(this.props.member.role_start_time).format('HH:mm:ss'),
                level: this.props.member.level,
                pay_point: this.props.member.pay_point,
                employment_type: this.props.member.employment_type,
                member_role_id: this.props.member.member_role_id
            }
            if (moment(this.props.member.role_end_time).isValid()) {
                state.role_end_date = moment(this.props.member.role_end_time).format('YYYY-MM-DD 00:00:00');
                state.role_end_time = moment(this.props.member.role_end_time).format('HH:mm:ss');
            }
            this.state = state;
        }
        // we'll use these refs to fix toggling slds datepicker issues
        this.datepickers = {
            role_start_date: React.createRef(),
            role_end_date: React.createRef()
        }

        this.formRef = React.createRef()
        this.permission = (checkLoginWithReturnTrueFalse()) ? ((getPermission() == undefined) ? [] : JSON.parse(getPermission())) : [];
        this.handleChangeDatePicker.bind(this);
    }

    handleChangeDatePicker = (key) => (dateYmdHis, e, data) => {
        if (e) {
            let newState = {}
            if (!key) {
                key = e.target.name;
            }
            newState[key] = dateYmdHis
            this.setState(newState)
        }
    }

    handleDatePickerOpened = k => () => {
        Object.keys(this.datepickers).forEach(refKey => {
            const { current } = this.datepickers[refKey] || {}
            if (refKey !== k && current && 'instanceRef' in current) {
                current.instanceRef.setState({ isOpen: false })
            }
        })
    }

    componentDidMount() {
        utils.getPayPoints().then((resp) => {
            this.setState({ 'PayPoints': resp.options });
        })
        utils.getLevels().then((resp) => {
            this.setState({ 'Levels': resp.options });
        })
        utils.getTimeSlots().then((resp) => {
            this.setState({ 'TimeOptions': resp.options.data });
        });
        /**
         * Get employment type options
         */
        utils.getEmploymentTypes().then((resp) => {
            this.setState({ 'employmentTypeOptions': resp.options });
        });
    }

    validateEndDate(endTime) {
        if (this.state.role_end_date === "Invalid Date") {
            this.setState({ role_end_date: null });
        }
        if (endTime === "Invalid Date") {
            endTime = null;
        }
        this.setState({ role_end_time: endTime });
        if (endTime && this.state.role_end_time && (!this.state.role_end_date || !moment(this.state.role_end_date).isValid())) {
            toastMessageShow("Invalid End Date", "e");
            return false;
        }
        return true;
    }

    onSubmit = (e) => {
        e.preventDefault();
        jQuery(this.formRef.current).validate({ /* */ });
        this.setState({ validation_calls: true })
        if (!this.state.loading && jQuery(this.formRef.current).valid()) {
            this.setState({ loading: true });
            let req = {
                member_id: this.state.member.value,
                role_id: this.state.role_details.value,
                start_date: this.state.role_start_date,
                start_time: this.state.role_start_time,
                end_date: this.state.role_end_date,
                end_time: this.state.role_end_time,
                pay_point: this.state.pay_point,
                level: this.state.level,
                employment_type: this.state.employment_type,
                member_role_id: this.state.member_role_id
            }
            if (!this.validateEndDate(this.state.role_end_time)) {
                this.setState({ loading: false });
                return false;
            }
            if (!this.state.role_start_date || !moment(this.state.role_start_date).isValid()) {
                toastMessageShow("Invalid Start Date", "e");
                this.setState({ loading: false });
                return false;
            }
            if (this.state.member.value !== this.props.member.member_id) {
                req.changed_member = this.state.member.label;
            }
            postData('member/MemberRole/create_update_member_role', req).then((result) => {
                if (result.status) {
                    toastMessageShow(result.msg, 's');
                    this.props.closeModal(true);
                } else {
                    toastMessageShow(result.error, "e");
                    this.setState({ loading: false });
                }
            });
        }
    }

    render() {

        return (
            <div>
                <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                    <div>
                        <Modal
                            isOpen={this.props.showModal}
                            footer={[
                                <Button disabled={this.state.loading} label="Cancel" onClick={() => this.props.closeModal(false)} />,
                                <Button disabled={this.state.loading} label="Save" variant="brand" onClick={(e) => this.onSubmit(e)} />,
                            ]}
                            onRequestClose={this.toggleOpen}
                            heading={this.state.member_role_id ? "Update Support Worker Role" : "Add Role to Support Worker"}
                            size="small"
                            className="slds_custom_modal"
                            onRequestClose={() => this.props.closeModal(false)}
                            dismissOnClickOutside={false}
                        >
                            <section className="manage_top" >
                                <div className="container-fluid">
                                    <form id="create_user" autoComplete="off" className="slds_form" ref={this.formRef} style={{ paddingBottom: 50, display: 'block' }}>
                                        <div className="row py-3">
                                            <div className="col-lg-6 col-sm-6">
                                                <div class="slds-form-element">
                                                    <label class="slds-form-element__label" for="text-input-id-1">
                                                        <abbr class="slds-required">* </abbr>Support Worker</label>
                                                    <div class="slds-form-element__control">
                                                        <SLDSReactSelect.Async clearable={false}
                                                            className="SLDS_custom_Select default_validation"
                                                            value={this.state.member || ''}
                                                            cache={false}
                                                            loadOptions={(e) => getOptionsMemberName(e)}
                                                            onChange={(e) => this.setState({ member: e })}
                                                            placeholder="Please Search"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6">
                                                <div class="slds-form-element">
                                                    <label class="slds-form-element__label" for="text-input-id-1">
                                                        <abbr class="slds-required" title="required">* </abbr>Role</label>
                                                    <div class="slds-form-element__control">
                                                        <SLDSReactSelect.Async clearable={false}
                                                            className="SLDS_custom_Select default_validation"
                                                            value={this.state.role_details}
                                                            cache={false}
                                                            required={true}
                                                            loadOptions={(e) => getRoles(e)}
                                                            onChange={(e) => this.setState({ role_details: e })}
                                                            placeholder="Please Search"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row py-2">
                                            <div className="col-lg-6 col-sm-6">
                                                <div className="slds-form-element">
                                                    <label className="slds-form-element__label" htmlFor="select-01"><abbr class="slds-required">* </abbr>Start Date</label>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                            <SLDSISODatePicker
                                                                ref={this.datepickers.role_start_date} // !important: this is needed by this custom SLDSISODatePicker
                                                                onChange={this.handleChangeDatePicker('role_start_date')}
                                                                onOpen={this.handleDatePickerOpened('role_start_date')}
                                                                onClear={this.handleChangeDatePicker('role_start_date')}
                                                                value={this.state.role_start_date || ''}
                                                                inputProps={{
                                                                    name: "role_start_date",
                                                                }}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-sm-6">
                                                <div className="slds-form-element">
                                                    <label className="slds-form-element__label" htmlFor="select-01">
                                                        <abbr className="slds-required" title="required">* </abbr>Start Time</label>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                            <SLDSReactSelect
                                                                simpleValue={true}
                                                                className="custom_select default_validation"
                                                                options={this.state.TimeOptions}
                                                                onChange={(value) => this.setState({ role_start_time: value })}
                                                                value={this.state.role_start_time}
                                                                clearable={false}
                                                                searchable={false}
                                                                placeholder="Please Select"
                                                                required={true}
                                                                name="role_start_time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row py-2">
                                            <div className="col-lg-6 col-sm-6">

                                                <div className="slds-form-element">
                                                    <label className="slds-form-element__label" htmlFor="select-01">End Date</label>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                            <SLDSISODatePicker
                                                                ref={this.datepickers.role_end_date} // !important: this is needed by this custom SLDSISODatePicker
                                                                className="role_end_date"
                                                                placeholder=""
                                                                onChange={this.handleChangeDatePicker('role_end_date')}
                                                                onOpen={this.handleDatePickerOpened('role_end_date')}
                                                                onClear={this.handleChangeDatePicker('role_end_date')}
                                                                value={this.state.role_end_date}
                                                                input={<Input name="role_end_date" />}
                                                                inputProps={{
                                                                    name: "role_end_date",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-lg-6 col-sm-6">
                                                <div className="slds-form-element">
                                                    <label className="slds-form-element__label" htmlFor="select-01">
                                                        End Time</label>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                            <SLDSReactSelect
                                                                simpleValue={true}
                                                                className="custom_select default_validation"
                                                                options={this.state.TimeOptions}
                                                                onChange={(value) => this.validateEndDate(value)}
                                                                value={this.state.role_end_time || moment(this.state.role_start_time).format('')}
                                                                clearable={true}
                                                                searchable={false}
                                                                placeholder="Please Select"
                                                                required={false}
                                                                name="role_end_time"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row py-2">
                                            <div className="col-lg-6 col-sm-6">
                                                <div className="slds-form-element">
                                                    <label className="slds-form-element__label" htmlFor="select-01">
                                                        <abbr className="slds-required" title="required">* </abbr>Pay Point</label>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                            <SLDSReactSelect
                                                                simpleValue={true}
                                                                className="custom_select default_validation"
                                                                options={this.state.PayPoints}
                                                                onChange={(value) => this.setState({ pay_point: value })}
                                                                value={this.state.pay_point || ''}
                                                                clearable={false}
                                                                searchable={false}
                                                                placeholder="Please Select"
                                                                required={true}
                                                                name="pay_point"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6">
                                                <div className="slds-form-element">
                                                    <label className="slds-form-element__label" htmlFor="select-01">
                                                        <abbr className="slds-required" title="required">* </abbr>Level</label>
                                                    <div className="slds-form-element__control">
                                                        <div className="SLDS_date_picker_width">
                                                            <SLDSReactSelect
                                                                simpleValue={true}
                                                                className="custom_select default_validation"
                                                                options={this.state.Levels
                                                                }
                                                                onChange={(value) => this.setState({ level: value })}
                                                                value={this.state.level || ''}
                                                                clearable={false}
                                                                searchable={false}
                                                                placeholder="Please Select"
                                                                required={true}
                                                                name="level"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row py-2">
                                            <div className="col-lg-6 col-sm-6">
                                                <div class="slds-form-element">
                                                    <label class="slds-form-element__label" for="text-input-id-1">
                                                        <abbr class="slds-required" title="required">* </abbr>Employment Type</label>
                                                    <div class="slds-form-element__control">
                                                        <SLDSReactSelect
                                                            simpleValue={true}
                                                            className="custom_select default_validation"
                                                            options={this.state.employmentTypeOptions}
                                                            onChange={(value) => this.setState({ employment_type: value })}
                                                            value={this.state.employment_type || ''}
                                                            clearable={false}
                                                            searchable={false}
                                                            placeholder="Please Select"
                                                            required={true}
                                                            name="employment_type"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </section>
                        </Modal>
                    </div>
                </IconSettings>
            </div >
        );
    }
}

const mapStateToProps = state => ({

})

const mapDispatchtoProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(CreateMemberRoleModal);
