import {orgActiveTitle} from 'menujson/org_menu_json';
import { postData,archiveALL,toastMessageShow} from 'service/common.js';


export const setOrgProfileData = (profileData) => ({
        type: 'set_org_profile_data',
        profileData
    })

export const setOrgActiveClassProfilePage = (value) => ({
        type: 'set_active_class_org',
        value
    })

export const setOrgBookingData = (profileData) => ({
        type: 'org_update_booking_record',
        profileData
    })

function getHouseProfileData(request) {
    return dispatch => {
        var postUrl = 'organisation/OrgDashboard/get_house_profile';
        return postData(postUrl, request).then((result) => {
            if (result.status) {
                dispatch(setOrgProfileData(result.data.basic_detail))
            } else {
                window.location = '/admin/organisation/dashboard';
            }
        });
    }
}

// middle ware for set data
export function getHouseProfile(request) {
    return (dispatch, getState) => {
        return dispatch(getHouseProfileData(request))
    }
}

export function setActiveSelectPage(pageType) {
    return (dispatch, getState) => {
        let pageTypeTitle = orgActiveTitle[pageType];
        var x = {pageType: pageType, pageTitle: 'Details'};

        return dispatch(setActiveSelectPageData(x))
    }
}

export const setActiveSelectPageData = (value) => {
    return {
        type: 'set_active_page_org',
        value
}}

export function reSendInvoiceEmail(invoiceId) {
        archiveALL({ invoiceId: invoiceId }, "Are you sure want to resend mail?", 'organisation/Organisation/resend_invoice_mail').then((result) => {
            if (result.status) {
                toastMessageShow(result.msg, "s")
                
            }else if(result.hasOwnProperty('error') || result.hasOwnProperty('msg')){
                let msg = result.hasOwnProperty('error') ? result.error:result.msg;
                toastMessageShow(msg,'e');
            }
        });     
    }



