import React from 'react';
import { ROUTER_PATH, BASE_URL } from '../../../config.js';
import { helpdeskJson } from 'menujson/helpdesk_menu_json';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';


import Sidebar from '../Sidebar';
import Enquiries from './Enquiries';
import CreateEnquiry from './CreateEnquiry';
import RemoteAccess from './RemoteAccess';
import ViewEnquiry from './ViewEnquiry.js';
import KnowledgeBase from './KnowledgeBase.js';
import { HelpdeskMenu } from '../GlobalMenu.jsx';

const menuJson = () => {
    let menu = helpdeskJson;
    return menu;
}

class AppHelpDesk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menus: menuJson(),
            showMobNav: false
        }
        //console.log('sdds',this.permission);
    }

    /**
     * Renders submenus of helpdesk module on the left sidebar.
     * Submenus will only show when browsing this module
     */
    renderHelpdeskModuleMenus() {
        return (
            <HelpdeskMenu>
                <Sidebar
                    heading={'Help Desk'}
                    menus={this.state.menus}
                    subMenuShowStatus={this.state.subMenuShowStatus}
                    // replacePropsData={this.state.replaceData}
                    renderMenusOnly
                />
            </HelpdeskMenu>
        )
    }


    render() {
        return (
            <React.Fragment>
                <div className='bodyNormal Helpdesk_M'>

                    <section className={'asideSect__ ' + (this.state.showMobNav ? 'open_left_menu' : '')}>
                        { this.renderHelpdeskModuleMenus() }
                        <div className="container-fluid fixed_size">
                            <div className='row justify-content-center d-flex'>
                                <div className='col-lg-11 col-md-12 col-sm-12 col-xs-12'>
                                    <Switch>
                                        <Route exact path={ROUTER_PATH + 'admin/helpdesk/'} render={() => <Redirect to={ROUTER_PATH + 'admin/helpdesk/enquiries'} />} />
                                        <Route exact path={ROUTER_PATH + 'admin/helpdesk/enquiries'} render={(props) => <Enquiries pageTypeParms='Enquiries' />} />
                                        <Route exact path={ROUTER_PATH + 'admin/helpdesk/remote_access'} render={(props) => <RemoteAccess  />} />
                                        <Route exact path={ROUTER_PATH + 'admin/helpdesk/create_enquiry'} render={(props) => <CreateEnquiry />} />
                                        <Route exact path={ROUTER_PATH + 'admin/helpdesk/view_enquiry/:id'} render={(props) => <ViewEnquiry />} />
                                        <Route exact path={ROUTER_PATH + 'admin/helpdesk/knowledge_base'} render={(props) => <KnowledgeBase />} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        )
    }
}

export default AppHelpDesk;