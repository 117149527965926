import React, { Component } from "react";
import Select from "react-select-plus";
import { PAGINATION_SHOW,ROUTER_PATH } from 'config.js';
import "react-select-plus/dist/react-select-plus.css";
import moment from "moment";
import { PanelGroup, Panel } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { checkLoginWithReturnTrueFalse, getPermission, postData, handleChangeChkboxInput } from 'service/common.js';
import { ToastContainer, toast } from 'react-toastify';
import { ToastUndo } from 'service/ToastUndo.js'
import Pagination from "service/Pagination.js";
import { defaultSpaceInTable } from 'service/custom_value_data.js';
import CreateReferenceData from './CreateReferenceData';
import '../../scss/components/admin/item/ReferenceData.scss';

const requestData = (pageSize, page, sorted, filtered) => {
  return new Promise((resolve, reject) => {
    var Request = JSON.stringify({ pageSize: pageSize, page: page, sorted: sorted, filtered: filtered });
    postData('recruitment/RecruitmentReferenceData/get_all_ref_data', Request).then((result) => {
      if (result.status) {
        let filteredData = result.data;
        const res = {
          rows: filteredData,
          pages: (result.count),
          all_count: result.all_count,
          total_duration: result.total_duration
        };
        resolve(res);
      }
    });
  });
};

class ReferenceDataList extends Component {
  constructor() {
    super();
    this.state = {
      CreateTaskShowModal: false,
      ref_data: [],
      srch_value: ''
    };
    this.permission = (checkLoginWithReturnTrueFalse()) ? ((getPermission() == undefined) ? [] : JSON.parse(getPermission())) : [];
  }

  fetchData = (state, instance) => {
    this.setState({ loading: true });
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      this.setState({
        ref_data: res.rows,
        all_count: res.all_count,
        pages: res.pages,
        loading: false,
      });
    })
  }

  searchData = (e) => {
    e.preventDefault();
    var requestData = { srch_value: this.state.srch_value };
    this.setState({ filtered: requestData });
  }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    CreateTaskCloseModal = (status) => {
        if (status) {
            //reFreashReactTable(this, 'fetchData');
        }
        this.setState({ CreateTaskShowModal: false })
    }

  render() {

    return (
      <React.Fragment>

        <section className='referencemodule'>
          <div className="row">
            <div className="col-lg-12 col-md-12 no-pad back_col_cmn-">
              <span
                onClick={e => window.history.back()}
                className="icon icon-back1-ie"
              />
            </div>
          </div>
          {/* row ends */}

          <div className="row referencemodule">
            <div className="col-lg-12 col-md-12 main_heading_cmn-">
              <h1>{this.props.showPageTitle}
                    <Link to={ROUTER_PATH + 'admin/item/create_reference_data'}>
                        <button className="btn hdng_btn cmn-btn1 icn_btn12">Create Reference Data <i className="icon icon-add-icons hdng_btIc"></i></button>
                    </Link>
              </h1>
            </div>
          </div>
          {/* row ends */}

          <div className="row action_cont_row referencemodule">
            <div className="col-lg-12 col-sm-12">
              <div className="tab-content">
                <div className="tasks_comp">
                  <div className="row sort_row1-- after_before_remove">
                    <div className="col-lg-7 col-md-7 col-sm-8 no_pd_l">
                      <div className="search_bar right srchInp_sm actionSrch_st">
                        <form autoComplete="off" onSubmit={this.searchData} method="post">
                          <input
                            name="srch_value"
                            type="text"
                            className="srch-inp"
                            placeholder="Search.."
                            onChange={(e) => handleChangeChkboxInput(this, e)}
                          />
                          <i className="icon icon-search2-ie" />
                        </form>
                      </div>
                    </div>

                  </div>

                  <div className="row">
                    <div className="col-sm-12 no-pad Req-Job-Opening_tBL">
                      <div className="listing_table PL_site th_txt_center__ odd_even_tBL  line_space_tBL H-Set_tBL">
                        <ReactTable
                          columns={[
                            {
                              accessor: "code",
                              headerClassName: '_align_c__ header_cnter_tabl',
                              Header: () =>
                                <div>
                                  <div className="ellipsis_line1__">Code</div>
                                </div>
                              ,
                              className: '_align_c__',
                              Cell: props => <span>{defaultSpaceInTable(props.value)}</span>
                            },
                            {
                              accessor: "type",
                              headerClassName: '_align_c__ header_cnter_tabl',
                              Header: () =>
                                <div>
                                  <div className="ellipsis_line1__">Type</div>
                                </div>
                              ,
                              className: '_align_c__',
                              Cell: props => <span>{defaultSpaceInTable(props.value)}</span>
                            },
                            {
                              accessor: "display_name",
                              headerClassName: '_align_c__ header_cnter_tabl',
                              Header: () =>
                                <div>
                                  <div className="ellipsis_line1__">Display Name</div>
                                </div>
                              ,
                              className: '_align_c__',
                              Cell: props => <span>{defaultSpaceInTable(props.value)}</span>
                            },

                            {
                              accessor: "source",
                              headerClassName: '_align_c__ header_cnter_tabl',
                              Header: () =>
                                <div>
                                  <div className="ellipsis_line1__">Source</div>
                                </div>
                              ,
                              className: '_align_c__',
                              Cell: props => <span>{(props.value)?defaultSpaceInTable(props.value):'N/A'}</span>
                            },

                            {
                              accessor: "start_date",
                              headerClassName: '_align_c__ header_cnter_tabl',
                              Header: () =>
                                <div>
                                  <div className="ellipsis_line1__">Start date</div>
                                </div>
                              ,
                              className: '_align_c__',
                              Cell: props => <span>{defaultSpaceInTable(props.value)}</span>
                            },
                            {
                              accessor: "end_date",
                              headerClassName: '_align_c__ header_cnter_tabl',
                              Header: () =>
                                <div>
                                  <div className="ellipsis_line1__">End date</div>
                                </div>
                              ,
                              className: '_align_c__',
                              Cell: props => <span>{defaultSpaceInTable(props.value)}</span>
                            },
                            {
                              expander: true,
                              Header: () => <strong />,
                              width: 45,
                              headerStyle: { border: "0px solid #fff" },
                              className: '_align_c__',
                              Expander: ({ isExpanded, ...rest }) => (
                                <div className="expander_bind">
                                  {isExpanded ? (
                                    <i className="icon icon-arrow-down icn_ar1" style={{ fontSize: '13px' }} />
                                  ) : (
                                      <i className="icon icon-arrow-right icn_ar1" style={{ fontSize: '13px' }} />
                                    )}
                                </div>
                              ),
                              style: {
                                cursor: "pointer",
                                fontSize: 25,
                                padding: "0",
                                textAlign: "center",
                                userSelect: "none"
                              }
                            }
                          ]}
                          manual
                          pages={this.state.pages}
                          loading={this.state.loading}
                          noDataText="No Record Found"
                          defaultPageSize={10}
                          data={this.state.ref_data}
                          minRows={2}
                          showPagination={this.state.ref_data.length > PAGINATION_SHOW ? true : false}
                          onFetchData={this.fetchData}
                          defaultFiltered={{ filter_by: 3, srch_value: '' }}
                          filtered={this.state.filtered}
                          PaginationComponent={Pagination}
                          previousText={<span className="icon icon-arrow-left privious"></span>}
                          nextText={<span className="icon icon-arrow-right next"></span>}
                          collapseOnDataChange={false}
                          className="-striped -highlight"
                          SubComponent={(props) => (
                            <div className="tBL_Sub applicant_info1 openingInfo clr_2">
                              <div className="jobMain_dets__ ">
                                <div className="row d-flex">
                                  <div className="col-md-4 bor-r-b">
                                    <ul className="jobMaindets_ul">
                                      <li className="mb-4">
                                        <b>Parent Id:</b> {(props.original.parent_id) ? props.original.parent_id : 'N/A'}
                                      </li>
                                    </ul>

                                  </div>
                                  <div className="col-md-8 pl-5">
                                    <p><b>Definition:</b></p>
                                    <div className="row">
                                      <div className="col-lg-6 col-sm-12">
                                        <p className="mb-0">{(props.original.definition) ? props.original.definition : 'N/A'}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*<div className="jobMain_foot__">
                                <div className="row">
                                  <div className="col-md-12 col-sm-12">
                                    <ul className="subTasks_Action__">
                                      <li>
                                        <span className="sbTsk_li">
                                            <Link to="#" ><small>Edit</small></Link>
                                        </span>
                                      </li>
                                      <li>
                                          <span className="sbTsk_li">
                                            <a href="#" className="decSpan" style={{ marginLeft: '0' }}><small>Archive</small></a>
                                          </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>*/}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* row ends */}
        </section>

        {
            (this.state.CreateTaskShowModal) ?
                <CreateReferenceData showModal={this.state.CreateTaskShowModal} closeModal={this.CreateTaskCloseModal} />
            : ''
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  showPageTitle: state.RecruitmentReducer.activePage.pageTitle,
  showTypePage: state.RecruitmentReducer.activePage.pageType
});

const mapDispatchtoProps = dispach => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(ReferenceDataList);
