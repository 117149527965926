import React from 'react';
import { ROUTER_PATH } from 'config.js';
import { Link } from 'react-router-dom';

class KnowledgeBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <div className=" py-4">
                            <span className="back_arrow">
                                <Link to={ROUTER_PATH + "admin/helpdesk"}><span className="icon icon-back1-ie"></span></Link>
                            </span>
                        </div>


                        <div className="by-1 ">
                            <div className="row d-flex  py-4">
                                <div className="col-lg-12 col-md-12">
                                    <div className="h-h1 color">Knowledge Base</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default KnowledgeBase;